import React, { ReactNode } from 'react';
import './style/index.css';
import { icons, illustrations } from '../../../assets/icons';
import mascot from '../../../assets/images/mascot.png';
import mascotDark from '../../../assets/images/mascot-dark.png';
import { renderToStaticMarkup } from 'react-dom/server';
import { IconVault } from '../../../utils/helper/Helper';
import { RavenButton } from '@ravenpay/raven-bank-ui';
import Loader from '../loader/index';
import { useDarkMode } from '../../../hooks/useDarkMode';

interface EmptyProps {
	imgToShow?: any;
	withMascot?: boolean;
	button?: boolean;
	buttonLabel?: string;
	btnIcon?: any;
	title?: string;
	subTitle?: string;
	isBankbox?: boolean;
	loading?: boolean;
	onClick?: (event?: Event) => any;
	loadingText?: string;
	mascot?: ReactNode;
	noMascot?: boolean;
}

function EmptyScreen(props: EmptyProps) {
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));

	return (
		<>
			{!props.isBankbox ? (
				<>
					{props.noMascot ? (
						<div
							style={{
								backgroundImage:
									theme === 'dark'
										? `url("${props?.imgToShow || IconVault(illustrations.no_graph_dark)}")`
										: '',
							}}
							className="no-record"
						>
							{props.loading ? (
								<Loader loadingText={props?.loadingText} />
							) : (
								<>
									<div className="no-record__text">
										<h5>{props.title ?? 'No Data at the moment'}</h5>
										<p>
											{props.subTitle ??
												'Transaction data chart show here once transfers and collections are been made.'}
										</p>
									</div>
								</>
							)}
						</div>
					) : (
						<div className="no-record-mascot">
							{props.loading ? (
								<Loader loadingText={props?.loadingText} />
							) : (
								<>
									<div
										style={{
											backgroundImage: `url("${IconVault(icons.bg_lines)}")`,
										}}
										className="no-record-mascot__top"
									>
										{/* <figure>{icons.bg_lines}</figure> */}
									</div>

									<div className="no-record-mascot__bottom">
										<h5>{props.title ?? 'Nothing to see here for now...'}</h5>
										<p>
											{props.subTitle ??
												'Virtual Accounts created on the platform get to show up here. There are none for now'}
										</p>

										{props.button && (
											<RavenButton
												onClick={props.onClick}
												color="deep-green-light"
											>
												<div className="button__wrap">
													{props.buttonLabel ?? 'Request Money'}
													{props.btnIcon !== false && (
														<figure>
															{props.btnIcon ?? icons.plus_circle_white}
														</figure>
													)}
												</div>
											</RavenButton>
										)}
									</div>
									<div className="mascot">
										{props.mascot || (
											<figure>
												<img
													src={theme === 'dark' ? mascotDark : mascot}
													alt=""
												/>
											</figure>
										)}
									</div>
								</>
							)}
						</div>
					)}
				</>
			) : (
				<></>
			)}
		</>
	);
}

export default EmptyScreen;
