import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './styles/index.css';
import { RavenButton, RavenInputField } from '@ravenpay/raven-bank-ui';
import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { businessCategories } from '../../data/businessCategory';
import { nigerianStates } from '../../data/statesInNigeria';
import { RavenInputProps } from '@ravenpay/raven-bank-ui/dist/esm/components/types';
import {
	capitalizeAllLetter,
	formatNumWithComma,
	reactSelectStyle,
	symbol,
} from '../../../../../utils/helper/Helper';

const UpdateBankboxUserModal = ({
	onComplete,
	detail,
	visible,
}: {
	onComplete: () => void;
	detail?: any;
	visible?: boolean;
}) => {
	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'personal',
		completed: [],
		label: 'Personal Details',
	});

	// const { all_fees_no_page } = useSelector((state: RootState) => state.bankbox);
	// const fileInputRef = useRef<HTMLInputElement>(null);
	// const imgPreviewRef = useRef<HTMLImageElement>(null);
	const { all_fees_no_page, partner_info } = useSelector(
		(state: RootState) => state.bankbox
	);
	const { profile } = useSelector((state: RootState) => state.settings);
	const [formData, setFormData] = React.useState({
		fname: '',
		lname: '',
		bvn: '',
		nin: '',
		email: '',
		phone: '',
		business_category: { value: undefined, label: undefined },
		business_description: '',
		region: { value: undefined, label: undefined },
		business_name: '',
		business_address: '',
		fee_category: { value: undefined, label: undefined } as any,
	});

	// useS

	const { loading } = useSelector((state: RootState) => state.bankbox);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};
	const dispatch = useDispatch();
	const handleSubmit = async () => {
		const val = {
			...formData,
			business_category: formData.business_category.label as unknown as string,
			region: formData.region.label as unknown as string,
			fee_ref: formData?.fee_category?.value,
			id: String(detail?.id),
			fee_category: formData?.fee_category?.id,
		};
		// console.log(val);

		// log
		const resp = await dispatch(bankboxAPI.updateUser(val));

		if (resp.payload?.status === 'success') {
			dispatch(
				bankboxAPI.getSingleUserMerchant({
					email: detail?.poseidon_email as string,
				})
			);
			dispatch(
				bankboxAPI.getAllUsers({
					per_page: 20,
					page: 1,
				})
			);
			setFormData((prev) => {
				return {
					...prev,
					fname: '',
					lname: '',
					bvn: '',
					nin: '',
					email: '',
					phone: '',
					business_category: { value: undefined, label: undefined },
					business_description: '',
					region: { value: undefined, label: undefined },
					business_name: '',
					business_address: '',
					fee_category: { value: undefined, label: undefined } as any,
				};
			});

			setStep({
				...step,
				id: 'personal',
			});
			onComplete();
		}
	};

	const getFeeObj = (param: any) => {
		// console.log(all_fees_no_page);

		const newObj = all_fees_no_page?.find(
			(chi: any) => String(chi?.id) === String(param)
		);
		// console.log(newObj);
		return newObj;
	};

	// const brandColors = ['0B8376', '0B8376', 'EA872D', 'B9DEFE'];

	function disabledAlgo() {
		let bool: boolean = false;
		if (step.id === 'personal') {
			if (
				!validateEmail(formData.email) ||
				!formData.lname ||
				!formData.fname ||
				!formData.phone
				//  ||
				// formData.bvn.length !== 11 ||
				// formData.nin.length !== 11
			) {
				bool = true;
			} else {
				bool = false;
			}
		}

		if (step.id === 'business') {
			if (
				!formData.business_name ||
				!formData.business_category ||
				!formData.business_description
			) {
				bool = true;
			} else {
				bool = false;
			}
		}

		if (step.id === 'address') {
			if (!formData.business_address || !formData.region) {
				bool = true;
			} else {
				bool = false;
			}
		}
		if (step.id === 'fees') {
			if (!formData.fee_category) {
				bool = true;
			} else {
				bool = false;
			}
		}
		return bool;
	}

	// const formatFeeCategory = () => {
	// 	// console.log(all_fees_no_page);

	// 	if (all_fees_no_page?.length > 0) {
	// 		const newList = all_fees_no_page?.map((chi: any) => {
	// 			return { ...chi, label: chi?.reference, value: chi?.id };
	// 		});
	// 		return newList;
	// 	}
	// };
	const [verificationType, setVerificationType] = useState('bank');
	useEffect(() => {
		// console.log(detail);
		const feeObj = {
			...getFeeObj(detail?.fee_ref),
			label: getFeeObj(detail?.fee_ref)?.reference,
			value: getFeeObj(detail?.fee_ref)?.id,
		};
		setFormData((prev) => {
			return {
				...prev,
				fname: detail?.fname,
				lname: detail?.lname,
				email: detail?.poseidon_email,
				phone: detail?.phone,
				business_name: detail?.poseidon_business_name || '',
				business_category: {
					label: detail?.business_category,
					value: detail?.business_category
						?.toLowerCase()
						?.replace(/[^a-zA-Z]/g, ''),
				},
				business_description: detail?.poseidon_business_description,
				region: { label: detail?.region, value: detail?.region?.toLowerCase() },
				business_address: detail?.poseidon_business_address,
				fee_category: feeObj,
			};
		});
		if (visible) {
			setStep({
				id: 'personal',
				completed: [],
				label: 'Personal Details',
			});
		}
	}, [visible]);
	return (
		<div
			onClick={() => {
				// console.log(formData);
			}}
			className="bankbox-modals"
			style={{ minHeight: '70rem' }}
		>
			<div className="bankbox-modals__left">
				<div className="bankbox-modals__left--bg-absolute"></div>
				<div className="bankbox-modals__left--stepper">
					<div
						onClick={() =>
							setStep({
								...step,
								id: 'personal',
							})
						}
						className={`stepper-item ${'active'} ${
							step.completed.includes('personal') && 'completed'
						}`}
					>
						<span
							onClick={() =>
								setStep({
									...step,
									id: 'personal',
								})
							}
						>
							<p>1</p>
						</span>
						<h6>Personal Details</h6>
					</div>

					<div
						onClick={() => {
							step.completed.includes('business') &&
								'completed' &&
								setStep({
									...step,
									id: 'business',
								});
						}}
						className={`stepper-item ${
							(step.id === 'business' || step.id === 'address') && 'active'
						} ${step.completed.includes('business') && 'completed'}`}
					>
						<span>
							<p>2</p>
						</span>
						<h6>Business Details</h6>
					</div>

					<div
						onClick={() => {
							step.completed.includes('address') &&
								'completed' &&
								setStep({
									...step,
									id: 'address',
								});
						}}
						className={`stepper-item ${step.id === 'address' && 'active'} ${
							step.completed.includes('address') && 'completed'
						}`}
					>
						<span>
							<p>3</p>
						</span>
						<h6>Address Details</h6>
					</div>
					{/* <div
						onClick={() => {
							step.completed.includes('address') &&
								'completed' &&
								setStep({
									...step,
									id: 'address',
								});
						}}
						className={`stepper-item ${step.id === 'fees' && 'active'} ${
							step.completed.includes('fees') && 'completed'
						}`}
					>
						<span>
							<p>4</p>
						</span>
						<h6>Fee Category</h6>
					</div> */}
				</div>
			</div>

			<div className="bankbox-modals__right">
				{step.id === 'personal' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Update Merchant</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<div className="price-content__input-contain--grouped">
								<RavenInputField
									name="fname"
									onChange={handleChange}
									placeholder="Enter First Name"
									label="First Name"
									value={formData.fname}
									color="deep-green-light"
								/>
								<RavenInputField
									name="lname"
									onChange={handleChange}
									placeholder="Enter Last Name"
									value={formData.lname}
									label="Last Name"
									color="deep-green-light"
								/>
							</div>

							<RavenInputField
								name="email"
								onChange={handleChange}
								placeholder="Enter Email Address"
								label="Email Address"
								value={formData.email}
								type="email"
								color="deep-green-light"
								disabled
							/>

							<RavenInputField
								name="phone"
								onChange={handleChange}
								label="Enter Phone No."
								value={formData.phone}
								placeholder="e.g 903 xxx xxx"
								type="phone"
								color="deep-green-light"
							/>

							{/* veri type start */}
							{partner_info?.partner_type === 'bank' && (
								<>
									{' '}
									<RavenInputField
										name="bvn"
										onChange={(e: { target: { value: string | any[] } }) => {
											if (e.target.value.length < 12) {
												handleChange(e);
											}
										}}
										placeholder="Enter BVN"
										label="BVN"
										value={formData.bvn}
										maxLength={11}
										max={11}
										maxSize={11}
										type="account-number"
										color="deep-green-light"
									/>
									<RavenInputField
										name="nin"
										placeholder="Enter NIN"
										value={formData.nin}
										onChange={(e: { target: { value: string | any[] } }) => {
											if (e.target.value.length < 12) {
												handleChange(e);
											}
										}}
										maxLength={11}
										max={11}
										maxSize={11}
										// pattern={'^[0-9a-f]'}
										label="NIN"
										type="text"
										color="deep-green-light"
									/>
								</>
							)}
							{/* veri type end */}
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() =>
									setStep({
										id: 'business',
										label: 'Business Details',
										completed: ['personal, business'],
									})
								}
								// disabled={disabledAlgo()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'business' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Update Merchant</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="business_name"
								onChange={handleChange}
								value={capitalizeAllLetter(formData.business_name)}
								placeholder="Business Name"
								label="Business Name"
								type="text"
								style={{ zIndex: '4400' }}
								color="deep-green-light"
								disabled={formData.business_name ? true : false}
							/>
							<RavenInputField
								name="business_category"
								onChange={(e: any) => {
									setFormData({
										...formData,
										business_category: e,
									});
								}}
								selectStyles={reactSelectStyle}
								placeholder="Select a Category"
								label="Business Category"
								value={formData.business_category}
								type="select"
								selectOption={businessCategories}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_description"
								placeholder="Describe your business"
								onChange={handleChange}
								value={formData.business_description}
								label="Business Description"
								type="textarea"
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() => {
									setStep({
										id: 'address',
										label: 'Address Details',
										completed: ['personal', 'business'],
									});
								}}
								// disabled={disabledAlgo()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'address' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Create User</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="region"
								placeholder="e.g 'Abia State'"
								label="Region"
								value={{
									value: formData.region.value ?? null,
									label: formData.region.label ?? null,
								}}
								selectStyles={reactSelectStyle}
								type="select"
								onChange={(e: { label: any }) => {
									handleChange({
										target: {
											name: 'region',
											value: e,
										},
									});
								}}
								selectOption={nigerianStates}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="business_address"
								placeholder="Write your address here"
								label="Address* "
								type="text"
								onChange={handleChange}
								value={formData.business_address}
								color="deep-green-light"
							/>
						</div>
						<div
							className="price-content__form-submit"
							style={{ width: '50%' }}
						>
							<RavenInputField
								type="submit"
								onSubmit={handleSubmit}
								color="deep-green-light"
								value="Update Merchant"
								// disabled={disabledAlgo()}
								loading={loading}
								loaderText="Updating merchant..."
							/>{' '}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default UpdateBankboxUserModal;
