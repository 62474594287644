import React, { useState } from 'react';
import AuthThreeLayoutWrap from '../../../layout/auth/AuthThreeLayoutWrap';
import { brands, icons } from '../../../assets/icons';
import './styles/index.css';
import {
	RavenInputField,
	RavenButton,
	RavenModal,
} from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';
import {
	validateEmail,
	validatePassword,
} from '../../../utils/helper/ValidateInput';
import { useDispatch, useSelector } from 'react-redux';
import {
	initiateForgotPassword,
	resetPassword,
	validateResetPasswordOTP,
} from '../../../redux/auth';
import RootState from '../../../redux/types';
import { logger } from '../../../services/logger';

const ForgetPasswordIndex = () => {
	interface ChangePassword {
		new_password: string;
		confirm_password: string;
	}

	const [email, setEmail] = useState<string>();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [completeToken, setCompleteToken] = useState<boolean>(false);
	const [token, setToken] = useState<string>('');
	const [stage, setStage] = useState<number>(1);
	const [password, setPassword] = useState<ChangePassword>({
		new_password: '',
		confirm_password: '',
	});

	function submitToken() {
		setShowModal(false);
		setStage(2);
	}

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootState) => state.auth);

	async function initResetPwd() {
		let resp = await dispatch(initiateForgotPassword({ email: email }));
		logger.log(resp.payload);

		if (resp?.payload?.status === 'success') {
			setShowModal(true);
		}
	}

	async function verifyOTP() {
		let resp = await dispatch(validateResetPasswordOTP({ otp: token }));
		logger.log(resp.payload);

		if (resp?.payload?.status === 'success') {
			setShowModal(false);
			setStage(2);
		}
	}

	async function resetPwd() {
		let resp = await dispatch(
			resetPassword({ otp: token, password: password?.new_password })
		);

		if (resp?.payload?.status === 'success') {
			navigate('/login');
		}
	}

	return (
		<AuthThreeLayoutWrap>
			<div className="forgot-password">
				<div className="forgot-password__logo">
					<figure>{brands.raven_alt}</figure>
					<h5>Bankbox</h5>
				</div>

				{stage === 1 && (
					<div className="forgot-password__body-wrap">
						<div
							onClick={() => navigate('/login')}
							className="body-wrap__back-to-login"
						>
							<figure>{icons.arrow_back}</figure>
							<p>Back to Login</p>
						</div>
						<div className="body-wrap__main-content">
							<h5 className="main-content__forgot-title"> Forgot Password</h5>
							<span>
								Kindly enter email address used in <br />
								registering your account
							</span>
							<div className="main-content__form">
								<RavenInputField
									type="email"
									placeholder="johnbull@gmail.com"
									label="Email Address*"
									value={email}
									onChange={(e: any) => setEmail(e.target.value)}
									color="blue-dark"
								/>

								<div className="form__c2a">
									<RavenButton
										size="big"
										style={{ width: '100%' }}
										label="Continue"
										disabled={!validateEmail(email)}
										onClick={initResetPwd}
										loading={loading}
										color="deep-green-light"
									/>
								</div>
							</div>
						</div>
					</div>
				)}

				{stage === 2 && (
					<div className="new-password__body-wrap">
						<div className="body-wrap__main-content">
							<h5 className="main-content__new-title"> Create New Password</h5>
							<span>One you won't forget next time...</span>
							<div className="main-content__form">
								<RavenInputField
									type="password"
									placeholder="J3***"
									label="Enter New Password*"
									value={password.new_password}
									showColor="purple-dark"
									onChange={(e: any) =>
										setPassword({
											...password,
											new_password: e.target.value,
										})
									}
									color="blue-dark"
								/>

								<RavenInputField
									type="password"
									placeholder="J3***"
									label="Confirm New Password*"
									value={password.confirm_password}
									showColor="purple-dark"
									onChange={(e: any) =>
										setPassword({
											...password,
											confirm_password: e.target.value,
										})
									}
									color="blue-dark"
								/>

								<div className="form__c2a">
									<RavenButton
										size="big"
										style={{ width: '100%' }}
										disabled={
											password.confirm_password !== password.new_password ||
											!validatePassword(password.new_password)
										}
										loading={loading}
										label="Confirm and Continue"
										onClick={resetPwd}
										color="deep-green-light"
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<RavenModal
				onClose={() => setShowModal(false)}
				visble={showModal }
				loading={loading}
				btnLabel="Verify Account"
				btnColor={`deep-green-light`}
				effect={`fadeInUp`}
				closeColor
				disabled={!completeToken}
				onBtnClick={verifyOTP}
				className={`auth-pin-modal-wrap`}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
					}}
					action=""
					className="form form-modal-auth"
				>
					<div className="text-box">
						<p className="big-title">Email Verification</p>
						<p className="small-title">
							Please kindly provide the 6-Digit OTP sent to the email provided
							to verify account.{' '}
							<span style={{ color: '#014345', fontWeight: 500 }}>
								{email || ""}
							</span>
						</p>
					</div>
					<RavenInputField
						type={`pin`}
						color={`black-light`}
						onChange={(e: string) => {
							setCompleteToken(false);
							setToken(e);
						}}
						onComplete={(pin: number) => {
							setCompleteToken(true);
							// handleSubmitDirect(pin);
						}}
						value={token}
						showCountDown
						// countDownTime={50}
						key={''}
						countdownFinishClick={() => {
							('');
						}}
						countdownFinishText={` Resend OTP`}
						countdownFinishClassName={`resend-text`}
						// onCountdownFinish
						countdownLabelClassName={`black-white-color`}
					/>
				</form>
			</RavenModal>
		</AuthThreeLayoutWrap>
	);
};

export default ForgetPasswordIndex;
