import { RavenInputField, RavenModal, toast } from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import '../styles/index.css';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import {
	capitalizeFirstLetter,
	checkIfAdminOwner,
	formatDateTwo,
	getActionNamesByPolicyName,
	reactSelectStyle,
} from '../../../../../utils/helper/Helper';
import { bankboxAPI } from '../../../../../redux/bankbox';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	onFinish?: () => void;
	detail?: any;
}

const ManageTeamModal: React.FC<MyComponentProps> = ({
	onClose,
	onFinish,
	visible,
	detail,
}) => {
	const { roles } = useSelector((state: RootState) => state.bankbox);
	const { profile } = useSelector((state: RootState) => state.settings);

	const dispatch = useDispatch();
	interface formComponent {
		email: string;
		first_name: string;
		last_name: string;
		role: any;
	}
	const [details, setDetails] = useState<formComponent>({
		email: '',
		first_name: '',
		last_name: '',
		role: '',
	});
	// const dispatch = useDispatch()

	const handleChange = (e: any) => {
		showError && setShowError(false);
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	useEffect(() => {
		if (visible) {
			// console.log(detail);
			setDetails({
				email: detail?.email,
				first_name: detail?.first_name,
				last_name: detail?.last_name,
				role: {
					label: capitalizeFirstLetter(detail?.role),
					value: detail?.role,
				},
			});
		}
	}, [visible]);

	const [showError, setShowError] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const handleSubmit = async () => {
		if (!validateEmail(details?.email)) {
			toast.error('Invalid email address!', {
				position: 'top-right',
			});
			setShowError(true);
			return;
		}
		const obj = {
			...details,
			role: details?.role?.value,
			id: detail?.id,
		};
		setLoading(true);
		// console.log(obj);
		const data = await dispatch(bankboxAPI.manageTeamMember(obj as any));
		// console.log(data);

		if (data?.payload?.status === 'success') {
			setLoading(false);
			dispatch(bankboxAPI?.getRoles({}));
			onFinish && onFinish();
			if (detail?.invitation_Status === 'Pending') {
				const obj = {
					email: details?.email,
					dont_toast: true,
				};
				dispatch(bankboxAPI.resendTeamInvite(obj as any));
			}

			handleClose();
		} else {
			setLoading(false);
		}
	};

	const formatRoleOption = (param: any) => {
		if (param?.length > 0) {
			const newList = param?.map((chi: any) => {
				return {
					...chi,
					label: capitalizeFirstLetter(chi?.roleName),
					value: chi?.roleName,
				};
			});
			return newList;
		}
	};

	const handleClose = () => {
		setDetails({ email: '', first_name: '', last_name: '', role: '' });
		onClose && onClose();
	};

	return (
		<RavenModal
			onBtnClick={handleSubmit}
			btnLabel="Update Member Info"
			btnColor="deep-green-light"
			onClose={() => {
				onClose && handleClose();
			}}
			loading={loading}
			disabled={
				details?.first_name === detail?.first_name &&
				details?.last_name === detail?.last_name &&
				details?.role?.value === detail?.role
					? true
					: false
			}
			visble={visible}
			className={`session-expired-wrap-modal`}
		>
			<div className="add-location-modal-index-wrap">
				<div className="title-text-box">
					<p className="title">Manage Member</p>
					<p className="text grey-white-color">Manage team member’s details.</p>
				</div>
				{/* form box styart */}
				<div className="form-box">
					{/* two group start */}
					<div
						style={{ position: 'relative', zIndex: 10 }}
						className="two-group-box"
					>
						<form autoComplete="off" style={{ width: '100%' }} action="">
							<RavenInputField
								color="deep-green-light"
								label="First name * "
								type="text"
								placeholder="Enter First name"
								value={details?.first_name}
								name={`first_name`}
								onChange={handleChange}
							/>
						</form>
						<form autoComplete="off" style={{ width: '100%' }} action="">
							{' '}
							<RavenInputField
								color="deep-green-light"
								label="Last name * "
								type="text"
								placeholder="Enter last name"
								value={details?.last_name}
								name={`last_name`}
								onChange={handleChange}
							/>
						</form>
					</div>
					{/* two group end */}
					<RavenInputField
						color="deep-green-light"
						label="Email"
						type="text"
						disabled
						placeholder="Address"
						value={details?.email}
						onChange={handleChange}
						name={`email`}
						showError={showError}
					/>

					<form
						style={{ position: 'relative', zIndex: '10', width: '100%' }}
						autoComplete="off"
						action=""
					>
						<RavenInputField
							selectStyles={reactSelectStyle}
							color="deep-green-light"
							label="Role * "
							type="select"
							placeholder="Select Role"
							value={details?.role}
							menuPlacement={`top`}
							onChange={(e: any) => {
								setDetails((prev) => {
									return { ...prev, role: e };
								});
							}}
							disabled={
								getActionNamesByPolicyName(
									profile?.rolePolicies?.policies,
									'roles'
								)?.includes('assign_role')
									? checkIfAdminOwner(details?.role?.value)
									: true
							}
							selectOption={formatRoleOption(roles)}
							// onMenuOpen={() => {
							// 	console.log(checkIfAdminOwner(details?.role?.value));
							// }}
						/>
					</form>
				</div>
				{/* form box end */}
			</div>
		</RavenModal>
	);
};

export default ManageTeamModal;
