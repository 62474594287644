/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import AuthOneLayoutWrap from '../../../layout/auth/AuthOneLayoutWrap';
import { brands, icons } from '../../../assets/icons';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	toast,
} from '@ravenpay/raven-bank-ui';
import './styles/index.css';
import {
	validateEmail,
	validatePassword,
	validatePasswordLength,
	validatePasswordLowercase,
	validatePasswordNumber,
	validatePasswordSpecialCharacter,
	validatePasswordUpperCase,
} from '../../../utils/helper/ValidateInput';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	SET_TOKEN,
	registerUser,
	registerUserOnBankBox,
	sendEmailVerification,
	validateRegistrationOTP,
} from '../../../redux/auth';
import { AppDispatch } from '../../../redux/store';
import RootState, { RegisterPayload } from '../../../redux/types';
import {
	filterEmptyColumns,
	genCaptchaKeyAsync,
	setCookie,
} from '../../../utils/helper/Helper';
import { checkEmailExist } from '../../../redux/bankbox';

const RegisterIndex = () => {
	const [activeStep, setActiveStep] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [completeToken, setCompleteToken] = useState(false);

	type RegisterProp = {
		first_name: string;
		last_name: string;
		email: string;
		password: string;
		token?: number;
		business_email?: string;
		business_name: string;
		phone_number: string;
		selected_feature?: any;
	};
	const [formData, setFormData] = useState<RegisterProp>({
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		business_name: '',
		business_email: '',
		phone_number: '',
		selected_feature: [],
	});

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { loading } = useSelector((state: RootState) => state.auth);

	const SITE_KEY = process.env.REACT_APP_gCAPTCHA_SITE_KEY;

	function handleFieldChange(e: any) {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	}

	// function submitToken() {
	// 	setActiveStep(2);
	// 	setShowModal(false);
	// }

	function selectFeature(e: any) {
		if (isSelected(e)) {
			const newArr = formData.selected_feature.filter((d: string) => d !== e);
			setFormData({
				...formData,
				selected_feature: [...newArr],
			});
		} else {
			setFormData({
				...formData,
				selected_feature: [...formData?.selected_feature, e],
			});
		}
	}

	function isSelected(feature: string) {
		return formData?.selected_feature?.includes(feature);
	}

	const [showError, setShowError] = useState({
		email: false,
		business_email: false,
		password: false,
	});

	const [finalSubmitLoading, setFinalSubmitLoading] = useState(false);
	const handleSubmit = async () => {
		setFinalSubmitLoading(true);
		const obj: RegisterPayload = {
			...formData,
			fname: formData.first_name,
			lname: formData.last_name,
			phone: formData.phone_number,
		};

		// console.log(filterEmptyColumns(obj));

		const data = await dispatch(
			registerUser(filterEmptyColumns(obj) as any) as any
		);
		// logger.log(data);
		if (data?.payload?.data?.status === 'success') {
			await setCookie('token', data?.payload?.data?.data?.data?.token, 3300);
			await dispatch(SET_TOKEN(data?.payload?.data?.data?.data?.token));
			// navigate('/dashboard-overview');

			const dataTwo = await dispatch(
				registerUserOnBankBox(filterEmptyColumns(obj) as any) as any
			);
			// console.log(dataTwo);

			if (dataTwo?.payload?.data?.status === 'success') {
				const appKey = dataTwo?.payload?.data?.data?.data?.appKey;
				// console.log(appKey);

				setCookie('x-pos-key', appKey, 3000);
				navigate('/dashboard-overview');
				setFinalSubmitLoading(false);
			} else {
				setFinalSubmitLoading(false);
			}
		} else {
			setFinalSubmitLoading(false);
		}
	};

	// const [showEmailError, setShowEmailError] = useState(false);
	const [loadingFirst, setLoadingFirst] = useState(false);
	async function initReg() {
		setLoadingFirst(true);
		const data = await dispatch(
			checkEmailExist({
				email: formData?.email,
				phone_number: formData?.phone_number,
			})
		);
		// console.log(data);

		if (
			data?.payload?.data?.message === 'partner info reterieved successfully'
		) {
			toast.error('Email already exist.', {
				position: 'top-right',
			});
			setShowError((prev) => {
				return { ...prev, email: true };
			});
			setLoadingFirst(false);
		}
		if (data?.payload?.data === 'email not found') {
			setShowModal(true);
			setLoadingFirst(false);
			genCaptchaKeyAsync(SITE_KEY).then((d) => {
				if (d) {
					setCookie('g-rec-response', d.toString(), 30);
					dispatch(sendEmailVerification({ email: formData?.email }));
				}
			});
		}
	}
	const [loadinOtp, setLoadingOtp] = useState(false);
	async function validateOTP(pin: string | number) {
		setShowModal(true);
		setLoadingOtp(true);
		const resp = await dispatch(
			validateRegistrationOTP({ email: formData?.email, otp: pin })
		);

		if (resp?.payload?.status === 'success') {
			setShowModal(false);
			setLoadingOtp(false);
			setActiveStep(2);
			setCompleteToken(false);
			setFormData((prev: any) => {
				return { ...prev, token: '' };
			});
		} else {
			setCompleteToken(false);
			setFormData((prev: any) => {
				return { ...prev, token: '' };
			});
			setLoadingOtp(false);
		}
	}

	const backImg = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.76485 12L5.85889 8L9.76485 4"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	return (
		<AuthOneLayoutWrap secondFrame={activeStep !== 1 ? true : false}>
			<div className="register">
				<div className="register__atlas-logo">
					<figure>{brands.raven_atlas}</figure>
					{/* <p>Bankbox</p> */}
				</div>
				{activeStep === 1 && (
					<>
						<div
							style={{ marginBottom: '1.5rem' }}
							className="register__onboarding-step"
						>
							<p
								className={
									formData?.email &&
									formData?.phone_number &&
									formData?.password
										? 'active'
										: ''
								}
							></p>
							<p></p>
						</div>
						<div className="register__create-account">
							<h5>Create Account</h5>
							<p>It's fast and easy!</p>
						</div>
						<div className="register__form">
							<RavenInputField
								placeholder="johnbull@gmail.com"
								label="Email Address*"
								color="green-dark"
								type="email"
								value={formData?.email}
								onChange={(e: any) => {
									if (showError?.email) {
										setShowError((prev) => {
											return { ...prev, email: false };
										});
									}
									handleFieldChange(e);
								}}
								name="email"
								showError={showError?.email}
							/>

							<RavenInputField
								style={{ color: 'black' }}
								placeholder="903 56** *** *"
								label="Business Mobile Number*"
								color="green-dark"
								type="phone"
								value={formData.phone_number}
								onChange={handleFieldChange}
								name="phone_number"
							/>
							<RavenInputField
								placeholder="********"
								label="Password*"
								color="green-dark"
								showColor="purple-dark"
								type="password"
								value={formData?.password}
								onChange={(e: any) => {
									if (showError?.password) {
										setShowError((prev) => {
											return { ...prev, password: false };
										});
									}
									handleFieldChange(e);
								}}
								name="password"
								showPasswordStrength={true as unknown as string}
								// onChange={handleFieldChange}
							/>

							<div className="form__action">
								<RavenButton
									loading={loadingFirst}
									style={{ width: '100%' }}
									label="Continue"
									color="deep-green-light"
									disabled={
										!formData.email ||
										!validatePassword(formData.password) ||
										!formData.phone_number
									}
									size="big"
									onClick={() => {
										if (!validateEmail(formData?.email)) {
											toast.error('Invalid email address!', {});
											setShowError((prev) => {
												return { ...prev, email: true };
											});
											return;
										}
										if (!validatePasswordLowercase(formData?.password)) {
											toast.error(
												'Password should have at least 1 lowercase letter',
												{
													position: 'top-right',
												}
											);
											setShowError((prev) => {
												return { ...prev, password: true };
											});
											return;
										}
										if (!validatePasswordUpperCase(formData?.password)) {
											toast.error(
												'Password should have at least 1 capital letter',
												{
													position: 'top-right',
												}
											);
											setShowError((prev) => {
												return { ...prev, password: true };
											});
											return;
										}
										if (!validatePasswordSpecialCharacter(formData?.password)) {
											toast.error(
												'Password should have at least 1 special character',
												{
													position: 'top-right',
												}
											);
											setShowError((prev) => {
												return { ...prev, password: true };
											});
											return;
										}
										if (!validatePasswordNumber(formData?.password)) {
											toast.error(
												'Password should have at least  1 numeric character.',
												{
													position: 'top-right',
												}
											);
											setShowError((prev) => {
												return { ...prev, password: true };
											});
											return;
										}
										if (!validatePasswordLength(formData?.password)) {
											toast.error(
												'Password should at least be 8 characters long',
												{
													position: 'top-right',
												}
											);
											setShowError((prev) => {
												return { ...prev, password: true };
											});
											return;
										}
										initReg();
									}}
								/>
								<div className="action__have-an-account">
									<p>Have an account ?</p>
									<span onClick={() => navigate('/login')}>
										Log In {icons.arrow_right_purple}
									</span>
								</div>
							</div>
						</div>
					</>
				)}
				{activeStep === 2 && (
					<>
						<div className="register__onboarding-step">
							{/* <p></p> */}
							<p
								className={
									formData?.email &&
									formData?.phone_number &&
									formData?.password
										? 'active'
										: ''
								}
							></p>
							<p
								className={
									formData?.email &&
									formData?.phone_number &&
									formData?.password &&
									formData.business_name &&
									formData.first_name &&
									formData.last_name
										? 'active'
										: ''
								}
							></p>
						</div>
						<div className="back-stepbox-wrap">
							<div
								onClick={() => {
									setActiveStep(1);
								}}
								className="img-back-wrap-boc"
							>
								<figure className="img-box">{backImg}</figure>
							</div>
							<div className="register__business-details">
								<h5>Business Details</h5>
								<p>Let’s get to know your business better</p>
							</div>
						</div>

						<div className="register__form">
							<RavenInputField
								placeholder="e.g Okonkwo & Sons LTD"
								label="Business Name*"
								color="green-dark"
								showColor="purple-dark"
								type="text"
								onChange={handleFieldChange}
								value={formData.business_name}
								name="business_name"
							/>
							<div className="form__grouped">
								<RavenInputField
									placeholder="Emmanuel"
									label="Firstname*"
									color="green-dark"
									type="text"
									onChange={handleFieldChange}
									name="first_name"
								/>
								<RavenInputField
									placeholder="Ezeani"
									label="Lastname*"
									color="green-dark"
									type="text"
									onChange={handleFieldChange}
									name="last_name"
								/>
							</div>
							<div className="form__action">
								<RavenButton
									style={{ width: '100%' }}
									label="Continue"
									color="deep-green-light"
									disabled={
										!formData.business_name ||
										!formData.first_name ||
										!formData.last_name
									}
									size="big"
									onClick={handleSubmit}
									loading={loading || finalSubmitLoading}
								/>
							</div>
						</div>
					</>
				)}

				{/* {activeStep === 3 && (
					<>
						<div className="register__onboarding-step">
							<p></p>
							<p className="active"></p>
						</div>

						<div className="register__business-details">
							<h5>Feature Preference</h5>
							<p>What would you love to use Raven Bankbox for?</p>
						</div>

						<div className="register__feature-select">
							<div
								onClick={() => selectFeature('collection')}
								className={`feature-select__item ${
									isSelected('collection') && 'active'
								}`}
							>
								<p>Collection & Remittance</p>
								{isSelected('collection') && <figure>{icons.selected}</figure>}
							</div>

							<div
								onClick={() => selectFeature('kyc')}
								className={`feature-select__item ${
									isSelected('kyc') && 'active'
								}`}
							>
								<p>KYC & Verifications</p>
								{isSelected('kyc') && <figure>{icons.selected}</figure>}
							</div>

							<div
								onClick={() => selectFeature('virtual-accounts')}
								className={`feature-select__item ${
									isSelected('virtual-accounts') && 'active'
								}`}
							>
								<p>Virtual Accounts</p>
								{isSelected('virtual-accounts') && (
									<figure>{icons.selected}</figure>
								)}
							</div>

							<div
								onClick={() => selectFeature('issuing')}
								className={`feature-select__item ${
									isSelected('issuing') && 'active'
								}`}
							>
								<p>Card Issuing</p>
								{isSelected('issuing') && <figure>{icons.selected}</figure>}
							</div>

							<div
								onClick={() => selectFeature('exploring')}
								className={`feature-select__item ${
									isSelected('exploring') && 'active'
								}`}
							>
								<p>Just Exploring your tools</p>
								{isSelected('exploring') && <figure>{icons.selected}</figure>}
							</div>
						</div>

						<div className="register__feature-submit">
							<RavenButton
								style={{ width: '100%' }}
								label="Continue"
								loading={loading}
								color="deep-green-light"
								disabled={formData.selected_feature.length === 0}
								size="big"
								onClick={handleSubmit}
							/>
						</div>
					</>
				)} */}
			</div>
			{/* <figure className="register__live-chat">{icons.live_chat}</figure> */}
			<RavenModal
				onClose={() => {
					setCompleteToken(false);
					setFormData((prev: any) => {
						return { ...prev, token: '' };
					});
					setShowModal(false);
				}}
				visble={showModal}
				loading={loading || loadinOtp}
				btnLabel="Verify Account"
				btnColor={`deep-green-light`}
				effect={`fadeInUp`}
				closeColor
				disabled={!completeToken}
				onBtnClick={() => validateOTP(formData.token as string | number)}
				className={`auth-pin-modal-wrap`}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
					}}
					action=""
					className="form form-modal-auth"
				>
					<div className="text-box">
						<p className="big-title">Email Verification</p>
						<p className="small-title">
							Please kindly provide the 6-Digit OTP sent to the email provided
							to verify account.{' '}
							<span style={{ color: '#014345', fontWeight: 500 }}>
								{formData?.email}
							</span>
						</p>
					</div>
					<RavenInputField
						type={`pin`}
						color={`black-light`}
						onChange={(e: any) => {
							setCompleteToken(false);
							setFormData((prev) => {
								return { ...prev, token: e };
							});
						}}
						loading={loading}
						onComplete={(pin: number) => {
							setCompleteToken(true);
							validateOTP(pin);
						}}
						value={formData?.token}
						showCountDown
						// countDownTime={268}
						key={showModal as any}
						countdownFinishClick={() => {
							('');
						}}
						countdownFinishText={` Resend OTP`}
						countdownFinishClassName={`resend-text`}
						// onCountdownFinish
						countdownLabelClassName={`black-white-color`}
					/>
				</form>
			</RavenModal>
		</AuthOneLayoutWrap>
	);
};

export default RegisterIndex;
