import {
	RavenActionBar,
	RavenButton,
	RavenModal,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useEffect, useState } from 'react';
import { icons } from '../../../../../assets/icons';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState, { BankBoxData } from '../../../../../redux/types';
import Loader from '../../../../../components/common/loader';
import {
	capitalizeAllLetter,
	formatDateTime,
	formatNumWithComma,
	formatNumWithCommaNairaSymbolTwo,
	getActionNamesByPolicyName,
	symbol,
} from '../../../../../utils/helper/Helper';
import { useNavigate } from 'react-router-dom';
// import { logger } from '../../../../../services/logger';
// import PageLayout from '../../../../../layout/dashboard/tableLayout';
// import ContainerLayout from '../../../../../layout/dashboard/container';
import TitleTextBox from '../../../../../components/common/TitleTextBox';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
// import AlertReceivers from './AlertReceivers';
import RavenFlier from './raven-flier/RavenFlier';
import FlierTwoIndex from './FlierTwoBox/FlierTwoIndex';

function SingleViewBankbox() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const searchParam = new URLSearchParams(window.location.search);
	const { single_bankbox, loading } = useSelector(
		(state: RootState) => state.bankbox
	);
	// const sn = searchParam.get('serial');

	// logger.log(sn, 'serial no');
	const [showAction, setShowAction] = useState(false);
	const [showFlier, setShowFlier] = useState(false);
	const [captureImg, setCaptureImg] = useState(false);

	// useEffect(() => {
	// 	// console.log(single_bankbox["0"]);

	// 	if (single_bankbox && String(single_bankbox[0]?.status) === '0') {
	// 		setShowAction(true);
	// 		// console.log("iguhij");
	// 	} else {
	// 		setShowAction(false);
	// 	}
	// }, [single_bankbox, sn]);

	const [refreshTable, setRefreshTable] = useState(false);
	const { profile } = useSelector((state: RootState) => state.settings);
	React.useEffect(() => {
		const serial_no = localStorage?.getItem('single-ter-serial');
		if (serial_no) {
			try {
				new Promise((resolve) => {
					dispatch(
						bankboxAPI.getSingleBankbox({ serial_no: serial_no as string })
					)
						.then((d) => {
							if (d?.payload?.status === 'success') {
								// console.log(d?.payload);
								const account_no = d?.payload?.data?.data?.account_number;
								if (account_no) {
									const obj = {
										account_number: account_no,
									};
									getFlierFunc(obj);
								}
								if (d?.payload?.data?.data?.terminal?.status !== 1) {
									setShowAction(true);
								} else {
									setShowAction(false);
								}

								resolve(d);
							} else {
								console.error('API Request failed'); // Log the error message
							}
						})
						.catch((error) => {
							console.error(error); // Log any error that occurs in the Promise chain
						});
				});
			} catch (error) {
				toast.error('Could not fetch details');
			}
		} else {
			navigate('/dashboard-bankbox/terminals');
		}
	}, [refreshTable]);

	// logger.log(single_bankbox, 'all_bankbox');

	const getFlierFunc = async (param: any) => {
		const resp = await dispatch(bankboxAPI.getSingleBankboxFlier(param));
	};

	const bb = single_bankbox;
	const [showDeactivate, setShowDeactivate] = useState<{
		on: boolean;
		chi: BankBoxData;
	}>({
		on: false,
		chi: {} as BankBoxData,
	});
	// const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
	// const [singleChild, setSingleChild] = useState<any>({});
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	const viewIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="dull-bright-filter"
				d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				className="dull-bright-filter"
				d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'terminals'
				)?.includes('delete_terminals') && (
					<div
						style={{
							opacity: '1',
							cursor: 'pointer',
						}}
						onClick={() => {
							setShowDeactivate({
								on: true,
								chi: chi,
							});
						}}
						className="more_item"
					>
						<figure style={{ width: '2.5rem', height: '2.5rem' }}>
							{chi?.terminal?.status === 1 ? (
								icons.deactivate_terminal
							) : (
								<svg
									className="img"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{
										width: '100%',
										height: '100%',
										objectFit: 'contain',
									}}
								>
									<circle cx="12" cy="12" r="12" fill="#F7F8F7" />
									<path
										d="M16 9L10.5 14.5L8 12"
										stroke="#1ACE37"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)}
						</figure>
						<p>
							{chi?.terminal?.status === 1
								? 'Deactivate Terminal'
								: 'Activate BankBox'}
						</p>
					</div>
				)}
				{/* <div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setShowModalDelete(true);
						setSingleChild(chi);
						console.log(chi);
					}}
					className="more_item"
				>
					<figure>
						{
							<svg
								className="img"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="12" cy="12" r="12" fill="#F7F7F7" />
								<path
									d="M13.5 7V7.7C13.5 7.98003 13.5 8.12004 13.4455 8.227C13.3976 8.32108 13.3211 8.39757 13.227 8.4455C13.12 8.5 12.98 8.5 12.7 8.5H11.3C11.02 8.5 10.88 8.5 10.773 8.4455C10.6789 8.39757 10.6024 8.32108 10.5545 8.227C10.5 8.12004 10.5 7.98003 10.5 7.7V7M10.1 17H13.9C14.4601 17 14.7401 17 14.954 16.891C15.1422 16.7951 15.2951 16.6422 15.391 16.454C15.5 16.2401 15.5 15.9601 15.5 15.4V8.6C15.5 8.03995 15.5 7.75992 15.391 7.54601C15.2951 7.35785 15.1422 7.20487 14.954 7.10899C14.7401 7 14.4601 7 13.9 7H10.1C9.53995 7 9.25992 7 9.04601 7.10899C8.85785 7.20487 8.70487 7.35785 8.60899 7.54601C8.5 7.75992 8.5 8.03995 8.5 8.6V15.4C8.5 15.9601 8.5 16.2401 8.60899 16.454C8.70487 16.6422 8.85785 16.7951 9.04601 16.891C9.25992 17 9.53995 17 10.1 17Z"
									stroke="#014345"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						}
					</figure>
					<p>View Alert Recievers</p>
				</div> */}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setShowFlier(true);
					}}
					className="more_item"
				>
					<figure style={{ width: '2.5rem', height: '2.5rem' }}>
						{viewIcon}
					</figure>
					<p>View Payment Flier</p>
				</div>
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	const threeDots = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
				stroke="#014345"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
				stroke="#014345"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
				stroke="#014345"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);

	async function handleDeactivate() {
		const resp = await dispatch(
			bankboxAPI.deactivateBankbox({ serial_no: showDeactivate?.chi?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowDeactivate({
				on: false,
				chi: {} as BankBoxData,
			});
			// navigate('/dashboard-bankbox/terminals');
			setShowAction(true);
			setRefreshTable(true);
		}
	}

	async function handleReactivate() {
		const resp = await dispatch(
			bankboxAPI.reactivateBankbox({ serial_no: showDeactivate?.chi?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowDeactivate({
				on: false,
				chi: {} as BankBoxData,
			});
			setShowAction(false);
			setRefreshTable(true);
		}
	}
	const [color_value, setColorValue] = useState<string>('');
	function addHashIfNeeded(str: any) {
		if (/\d/.test(str) && !/^#/.test(str)) {
			return '#' + str;
		} else {
			return str;
		}
	}
	async function fetchProfile() {
		const resp = await dispatch(bankboxAPI.getProfile({}));

		if (resp.payload?.status === 'success') {
			const color_code = resp.payload.data.data?.color_code;

			setColorValue(addHashIfNeeded(color_code));
		}
	}
	useEffect(() => {
		fetchProfile();
	}, []);

	return (
		<>
			{/* /dashboard-bankbox/terminals */}
			<DashboardLayout
				back
				onBack={() => {
					if (navigate.length > 1) {
						navigate(-1);
					} else {
						navigate('/dashboard-bankbox/terminals');
					}
				}}
			>
				{/* <PageLayout
					pageSubtitle="All BankBox created on the platform"
					pageTitle="BankBox Terminals"
					
					topRightContent={<></>}
				></PageLayout> */}

				<TitleTextBox
					title="BankBox Terminal"
					text="BankBox created on the platform"
					// back={true}
					onBack={() => {
						if (navigate.length > 1) {
							navigate(-1);
						} else {
							navigate('/dashboard-bankbox/terminals');
						}
					}}
				>
					{bb &&
						Object?.keys(bb)?.length > 0 &&
						!loading &&
						bb?.terminal?.status === 1 && (
							<div
								className="white-black-bg"
								style={{
									position: 'relative',
									width: '3.5rem',
									height: '3.5rem',
									borderRadius: '50%',
									display: 'grid',
									placeItems: 'center',
									cursor: 'pointer',
								}}
								onClick={(e) => {
									e.stopPropagation();
									setShowDrop((prev) => {
										return {
											...prev,
											id: bb?.id,
											show: showDrop?.id === bb?.id ? !showDrop.show : true,
										};
									});
								}}
							>
								<DropMore
									idx={bb?.id}
									className={isDropped(bb?.id) && 'show-drop'}
									chi={bb}
								/>
								{threeDots}
							</div>
						)}
				</TitleTextBox>
				<RavenActionBar
					visible={showAction}
					// visible={true}
					className={`general-actionbar  ${
						showAction && 'general-actionbar-bottom'
					}`}
					type="error"
					msg='This device is currently deacticated, Please click on "Reactivate Now" to activate.'
					actionText="Reactivate Now"
					key={`fyuk`}
					onCancel={() => {
						setShowAction(false);
					}}
					onAction={() => {
						setShowDeactivate({
							on: true,
							chi: bb,
						});
					}}
				/>
				<div className="bankbox-terminal-view">
					<div className="bankbox-terminal-view__left"></div>

					<div className="bankbox-terminal-view__right">
						{loading ? (
							<Loader />
						) : (
							<>
								<div
									// onClick={() => {
									// 	console.log(single_bankbox);
									// 	console.log(bb);
									// }}
									className="bankbox-terminal-view__right--title"
								>
									<div>
										<h6>{bb?.terminal?.tid_label || '---'}</h6>
										<p>Terminal Label</p>
									</div>
									{getActionNamesByPolicyName(
										profile?.rolePolicies?.policies,
										'terminals'
									)?.includes('view_terminal_transactions') && (
										<RavenButton
											className="view-term-trx-btn"
											label="View Transactions"
											onClick={() => {
												// console.log(bb?.terminal);

												localStorage.setItem(
													'serial-trans',
													bb?.terminal?.serial
												);
												// localStorage?.setItem("terminal-id-trans", bb?.terminal?.tid)
												navigate('/dashboard-bankbox/transaction-single');
												// navigate(
												// 	`/dashboard-bankbox/transactions?serial=${bb?.terminal?.serial}`
												// );
											}}
											color="deep-green-light"
										/>
									)}
								</div>
								<div className="bankbox-terminal-view__right--date-location">
									<div className="item">
										<figure>{icons.bankbox_serial}</figure>
										<p>Serial No. • {bb?.terminal?.serial}</p>
									</div>

									<div className="item">
										<figure>{icons.calendar_rounded_grey}</figure>
										<p>
											Created at. • {formatDateTime(bb?.terminal?.created_at)}
										</p>
									</div>

									{/* <div className="item">
										<figure>{icons.green_checkmark_rounded}</figure>
										<p>Activated • {bb?.updated_at}</p>
									</div> */}
								</div>
								<div className="bankbox-terminal-view__right--user-details">
									<div className="details-row">
										<div className="details">
											<span>Business Name</span>
											<p>
												{capitalizeAllLetter(
													bb?.terminal?.poseidon_business_name ||
														bb?.terminal?.business_name
												) || '---'}
											</p>
										</div>
										<div className="details">
											<span>Terminal ID</span>
											<p>{bb?.terminal?.tid}</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Owner's Name</span>
											<p>{`${bb?.business_info?.fname || '---'} ${
												bb?.business_info?.lname || '---'
											}`}</p>
										</div>
										<div className="details">
											<span>Transaction Count</span>
											<p>
												{formatNumWithCommaNairaSymbolTwo(
													bb?.total_transaction,
													{ hideSymbol: true, hideDecimal: true }
												) || '0'}
											</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Email</span>
											<p>{bb?.terminal?.poseidon_business_email || '---'}</p>
										</div>
										<div className="details">
											<span>Transaction Volume</span>
											<p>
												{formatNumWithCommaNairaSymbolTwo(bb?.total_volume)}
											</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Bank</span>
											<p>{bb?.account_info?.bank || '---'}</p>
										</div>
										<div className="details">
											<span>Account No</span>
											<p>{bb?.account_info?.account_number || '---'}</p>
										</div>
									</div>
									<div className="details-row">
										<div className="details">
											<span>Account Name</span>
											<p>{bb?.account_info?.account_name || '---'}</p>
										</div>
										<div style={{ opacity: 0 }} className="details">
											<span>Account No</span>
											<p>{bb?.account_info?.account_number || '---'}</p>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</DashboardLayout>

			<DeleteModal
				onDelete={
					showDeactivate?.chi?.terminal?.status === 1
						? handleDeactivate
						: handleReactivate
				}
				loading={loading}
				deleteText={
					showDeactivate.chi?.terminal?.status === 1 ? 'Deactivate' : 'Activate'
				}
				buttonColor={
					showDeactivate.chi?.terminal?.status !== 1 ? 'deep-green-light' : ''
				}
				visible={showDeactivate.on}
				onCancel={() =>
					setShowDeactivate({
						on: false,
						chi: {} as BankBoxData,
					})
				}
				title={
					showDeactivate.chi?.terminal?.status === 1
						? 'Deactivate BankBox'
						: 'Activate BankBox'
				}
				text={
					showDeactivate.chi?.terminal?.status === 1
						? 'Deactivating a BankBox suspend it from recieving or sending out money, are you sure you want to proceed?'
						: 'Activating this BankBox will activate the sending and receiving feature of the BankBox.'
				}
			/>
			{/* <AlertReceivers visible={true} /> */}
			{/* download flier modal start */}
			<RavenModal
				className={`session-expired-wrap-modal flier-modal-btn-box`}
				// btnLabel="Download Flier"
				visble={showFlier}
				// visble={true}
				onBtnClick={() => {
					// setCaptureImg(!captureImg);
				}}
				btnColor="deep-green-light"
				onClose={() => {
					setCaptureImg(false);
					setShowFlier(false);
				}}
			>
				<div className="flier-modal-wrap-box">
					{/* <RavenFlier onCapture={captureImg} visible={showFlier} /> */}
					<FlierTwoIndex color_code={color_value} />
				</div>
			</RavenModal>
			{/* download flier modal start */}
		</>
	);
}

export default SingleViewBankbox;
