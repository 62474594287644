import { RavenInputField, RavenModal, toast } from '@ravenpay/raven-bank-ui';
import React, { useState } from 'react';
import './style/CreateFeeCategoryModal.css';
import {
	formatNumWithComma,
	formatNumWithCommaNairaSymbol,
	formatNumWithoutCommaNaira,
	replaceSpacesWithNothing,
	symbol,
} from '../../../../utils/helper/Helper';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import { bankboxAPI } from '../../../../redux/bankbox';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	onFinish?: () => void;
}

const CreateFeeCategoryModal: React.FC<MyComponentProps> = ({
	visible,
	onClose,
	onFinish,
}) => {
	const [step, setStep] = useState(1);
	const stepList = [1, 2];
	const { profile } = useSelector((state: RootState) => state.wallet);
	const { loading_fee } = useSelector((state: RootState) => state.bankbox);
	const dispatch = useDispatch();
	interface formComponent {
		fee_category: string;
		rate: string;
		cap: string;
		transfer_fee: string;
		collection_amount: string;
		collection_fee: string;
		fee: string;
	}
	const [details, setDetails] = useState<formComponent>({
		fee_category: '',
		rate: '',
		cap: '',
		transfer_fee: '',
		collection_amount: '',
		collection_fee: '',
		fee: '',
	});

	const [showError, setShowError] = useState(false);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};

	const handleSubmit = async () => {
		const obj = {
			reference: details?.fee_category,
			cap: formatNumWithoutCommaNaira(details?.cap),
			mandatory_fee: formatNumWithoutCommaNaira(details?.fee),
			collection_amount: formatNumWithoutCommaNaira(details?.collection_amount),
			bank_collection_fee: formatNumWithoutCommaNaira(details?.collection_fee),
			transfer_fee: formatNumWithoutCommaNaira(details?.transfer_fee),
			partner: profile?.email,
			// partner: `${profile?.fname} ${profile?.lname}`,
			fee: details?.rate,
		};
		// console.log(details, "-----details-----");
		// console.log(obj, "-----obj-----");

		// return
		const data = await dispatch(bankboxAPI.createFeeCategory(obj as any));
		// console.log(data);
		if (data?.payload?.status === 'success') {
			setDetails((prev) => {
				return {
					...prev,
					fee_category: '',
					rate: '',
					cap: '',
					transfer_fee: '',
					collection_amount: '',
					collection_fee: '',
					fee: '',
				};
			});
			onFinish && onFinish();
		}
	};

	const [showMandatoryError, setShowMandatoryError] = useState(false);

	return (
		<RavenModal
			btnColor="deep-green-light"
			btnLabel={step === 1 ? 'Continue' : 'Create Fee Category'}
			loading={loading_fee}
			onBtnClick={() => {
				if (step === 1) {
					if (Number(details?.rate) > 100) {
						toast.error('Rate value can not be above 100 ', {
							position: 'top-right',
						});
						setShowError(true);
						return;
					} else if (Number(formatNumWithoutCommaNaira(details?.fee)) > 1000) {
						toast.error(
							`Mandatory fee value can not be above ${
								symbol('ngn') + formatNumWithComma(1000, 'ngn')
							}`,
							{
								position: 'top-right',
							}
						);
						setShowMandatoryError(true);
					} else {
						setStep(2);
					}
				} else {
					handleSubmit();
				}
			}}
			onClose={() => {
				setStep(1);
				onClose && onClose();
			}}
			visble={visible}
			className={`session-expired-wrap-modal`}
			disabled={
				(step === 1 &&
					(!details?.cap || !details?.fee_category || !details?.rate)) ||
				!details?.fee
					? true
					: step === 2 &&
					  (!details?.collection_fee ||
							// !details?.collection_amount ||
							!details?.transfer_fee)
					? true
					: false
			}
		>
			<div className="create-fee-category-modal-wrap-index">
				{/* title text start */}
				<div className="title-text-box">
					<p className="title">Create Fee Category</p>
					<p className="text grey-white-color">Your fee category details</p>
				</div>
				{/* title text end */}
				{/* grey step box start */}
				<div className="grey-step-wrap-box grey-bg">
					{stepList?.map((chi, idx) => {
						return (
							<div
								key={idx}
								className={`step ${step === chi && 'step-long'} ${
									step >= chi && 'step-active'
								}`}
								onClick={() => {
									if (
										chi === 2 &&
										details?.cap &&
										details?.fee_category &&
										details?.rate &&
										// details?.transfer_fee
										details?.fee
									) {
										setStep(2);
									}
									if (chi === 1) {
										setStep(chi);
									}
								}}
							></div>
						);
					})}
				</div>
				{/* grey step box end */}
				{/* step content box start */}
				<div className="step-content-wrap">
					{/* step one start */}
					{step === 1 && (
						<>
							<RavenInputField
								placeholder="Enter fee category"
								label="Fee Category"
								type="text"
								color="deep-green-light"
								value={replaceSpacesWithNothing(details?.fee_category)}
								name={`fee_category`}
								onChange={handleChange}
							/>
							<RavenInputField
								placeholder="E.g .5%"
								label="Rate (%)"
								type="number"
								numberUp={() => {
									setDetails((prev: any) => {
										return {
											...prev,
											rate: Number(details?.rate) + 1,
										};
									});
									showError && setShowError(false);
								}}
								// className={`wp-30`}
								numberSpinner
								numberDown={() => {
									Number(details?.rate) >= 1 &&
										setDetails((prev: any) => {
											return {
												...prev,
												rate: Number(details?.rate) - 1,
											};
										});
									showError && setShowError(false);
								}}
								color="deep-green-light"
								value={details?.rate}
								name={`rate`}
								onChange={(e: any) => {
									handleChange(e);
									showError && setShowError(false);
								}}
								showError={showError}
							/>
							<RavenInputField
								placeholder="E.g ₦100"
								label="Cap (₦)"
								type="number"
								color="deep-green-light"
								thousandFormat
								numberPrefix={`₦`}
								value={details?.cap}
								name={`cap`}
								onChange={handleChange}
							/>
							<RavenInputField
								placeholder="E.g ₦100"
								label="Mandatory Fee (₦)"
								type="number"
								color="deep-green-light"
								thousandFormat
								numberPrefix={`₦`}
								value={details?.fee}
								name={`fee`}
								showError={showMandatoryError}
								onChange={(e: any) => {
									showMandatoryError && setShowMandatoryError(false);
									handleChange(e);
								}}
							/>
							{/* <RavenInputField
								placeholder="Enter Transfer Fee"
								label="Transfer Fee"
								type="number"
								color="deep-green-light"
								thousandFormat
								numberPrefix={`₦`}
								value={details?.transfer_fee}
								name={`transfer_fee`}
								onChange={handleChange}
							/> */}
						</>
					)}
					{/* step one end */}
					{/* step two start */}
					{step === 2 && (
						<>
							<RavenInputField
								placeholder="Enter Transfer Fee"
								label="Transfer Fee"
								type="number"
								color="deep-green-light"
								thousandFormat
								numberPrefix={`₦`}
								value={details?.transfer_fee}
								name={`transfer_fee`}
								onChange={handleChange}
							/>
							{/* <RavenInputField
								placeholder="E.g ₦100 "
								label="Collection Amount"
								type="number"
								color="deep-green-light"
								thousandFormat
								numberPrefix={`₦`}
								value={details?.collection_amount}
								name={`collection_amount`}
								onChange={handleChange}
							/> */}
							<RavenInputField
								placeholder="E.g ₦100 "
								label="Collection Fee (₦)"
								type="number"
								color="deep-green-light"
								thousandFormat
								numberPrefix={`₦`}
								value={details?.collection_fee}
								name={`collection_fee`}
								onChange={handleChange}
							/>
						</>
					)}
					{/* step two end */}
				</div>
				{/* step content box end */}
			</div>
		</RavenModal>
	);
};

export default CreateFeeCategoryModal;
