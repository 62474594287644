import React from "react";
// import {
//   capitalizeFirstLetter,
// } from "../../utils/helper/Helper";
import "./DateFragmentBox.css";
import moment from "moment";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { capitalizeFirstLetter } from "../../utils/helper/Helper";
// import { capitalizeFirstLetter } from "../helper";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

interface DateFragmentBoxProps {
  date: string | Date;
  dontShowTime?: boolean;
  dontShowAgo?: boolean;
  dontShowSmallDate?: boolean;
  className?: string;
  parentStyles?: React.CSSProperties;
}

const DateFragmentBox: React.FC<DateFragmentBoxProps> = ({
  date,
  dontShowTime,
  dontShowAgo,
  dontShowSmallDate,
  className,
  parentStyles,
}) => {
  
  const formatDate = (date: string | Date): string => {
    const actualDate = String(moment(date).format("MMMM, DD, YYYY  — HH:mm a"));
    const splitDate = actualDate.split(",");
    const newDate =
      getOrdinalSuffix(splitDate[1]) +
      " " +
      splitDate[0].slice(0, 3) +
      "" +
      splitDate[2]?.replace("—", ",");
    return newDate;
  }

  const dontShowTimeFunc = (param: string): string | undefined => {
    if (param) {
      const val = param.split(",");
      return `${val[0]}, ${val[1]}`;
    }
  };

  const formatDateToRelativeTime = (date: string | Date): string => {
    if (date) {
      return dayjs().to(dayjs(date)) || "";
    }
    return "";
  };

  const getOrdinalSuffix = (number: string): string | undefined => {
    if (number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = Number(number) % 100;
      return (
        removeLeadingZeros(number) +
        (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
      );
    }
  }

  const removeLeadingZeros = (input: string): string => {
    if (input) {
      const number = Number(input);
      return number.toString();
    }
    return input;
  }

  return (
    <span
      style={parentStyles}
      className={`date-time-fragment-box-wrap ${className || ''}`}
    >
      {date && (
        <>
          {!dontShowAgo && (
            <span className="date">
              {capitalizeFirstLetter(formatDateToRelativeTime(date))}
            </span>
          )}
          {!dontShowSmallDate && (
            <span className="date-two date-time-fragment-box-wrap-date-two grey-color-white">
              {dontShowTime ? dontShowTimeFunc(formatDate(date)) : formatDate(date)}
            </span>
          )}
        </> 
      )}
    </span>
  );
};

export default DateFragmentBox;
