import { RavenButton } from '@ravenpay/raven-bank-ui';
import React from 'react';
import noContentImg from '../../../../../assets/images/mascot.png';
import './NoContentBox.css';
// import { Oval } from "react-loader-spinner";
import newImgLoader from '../../../../../assets/images/raven-loader.gif';
// import useWindowSize from "../../utils/helper/UseWindowSize";

interface MyComponentProps {
	title?: string;
	text?: string;
	btnText?: string;
	onBtnClick?: () => void;
	img?: any;
	loading?: boolean;
	bgColor?: string;
}

const NoContentBox: React.FC<MyComponentProps> = ({
	title,
	text,
	btnText,
	onBtnClick,
	img,
	loading,
	bgColor,
}) => {
	// const size = useWindowSize();
	if (loading) {
		return (
			<div
				style={{
					height: '100%',
					width: '100%',
					position: 'relative',
					zIndex: '10',
					display: 'grid',
					placeItems: 'center',
				}}
				className={`spinner-wrap ${bgColor && 'table-transaction-cover'}`}
			>
				{/* <Oval
          height={size.width < 900 ? 45 : 80}
          width={size.width < 900 ? 45 : 80}
          //   color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass="loader-spiner-react"
          visible={true}
          ariaLabel="oval-loading"
          // secondaryColor="#4fa94d"
          strokeWidth={2}
          strokeWidthSecondary={2}
        /> */}
				<figure
					// style={{ width: "15rem", height: "15rem" }}
					className="img-box"
				>
					<img
						// style={{ width: "100%", height: "100%", objectFit: "contain" }}
						src={newImgLoader}
						alt=""
						className="img"
					/>
				</figure>
				{/* <ThreeCircles
        height="70"
        width="70"
        // color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass="loader-spiner-react"
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      /> */}
				{/* <Rings
        height="120"
        width="120"
        //   color="#020202"
        radius="10"
        wrapperStyle={{}}
        wrapperClass="loader-spiner-react"
        visible={true}
        ariaLabel="rings-loading"
      /> */}
			</div>
		);
	}
	return (
		<div
			className={`no-content-reusuable-wrap-box ${
				bgColor && 'table-transaction-cover'
			}`}
		>
			<div className="wrap">
				<figure className="img-box">
					<img src={img || noContentImg} alt="" className="img" />
				</figure>
				<p className="title">{title}</p>
				<p className="text grey-white-color">{text}</p>
				{btnText && (
					<RavenButton
						className="no-btn"
						color={`deep-green-light`}
						label={btnText}
						onClick={onBtnClick}
					/>
				)}
			</div>
		</div>
	);
};

export default NoContentBox;
