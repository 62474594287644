import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './style/DisputeIndexPage.css';
import SmartFilter from '../../../../components/common/smartFilter';
import Pagination from '../../../../components/common/pagination';
import { RavenTable, RavenTableRow } from '@ravenpay/raven-bank-ui';
import DisputeDetailModal from './DisputeDetailModal';
import BadgeComponent from '../../../../components/common/badge/BadgeComponent';
import {
	capitalizeFirstLetter,
	checkDisputeStatus,
	checkDisputeStatusColor,
	formatDateTime,
	formatNumWithComma,
	getActionNamesByPolicyName,
	lowerCaseWrap,
	returnStatus,
	symbol,
	trimLongString,
} from '../../../../utils/helper/Helper';
import { bankboxAPI } from '../../../../redux/bankbox';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../redux/types';
import { debounce } from 'lodash';
import ExportModal from '../../../../components/common/exportModal';
import StatusUpdateModal from './StatusUpdateModal';
import DateFragmentBox from '../../../../components/common/DateFragmentBox';
// import { useNavigate } from 'react-router-dom';

const DisputeIndexPage = () => {
	// const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showExport, setShowExport] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [noContent, setNoContent] = useState(true);
	type showModalProp = {
		view: boolean;
		status: boolean;
	};
	const [showModal, setShowModal] = useState<showModalProp>({
		view: false,
		status: false,
	});
	const { all_disputes, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	const [singleChild, setSingleChild] = useState({});
	const [refreshTable, setRefreshTable] = useState(false);
	useEffect(() => {
		getFirstFetch();
	}, [currentPage || refreshTable, refreshTable]);

	const getFirstFetch = async () => {
		const data = await dispatch(
			bankboxAPI.getAllDisputes({
				per_page: 20,
				page: currentPage,
			})
		);
		// console.log(data);

		if (
			data?.payload?.status === 'success' &&
			data?.payload?.data?.data?.data?.length > 0
		) {
			setNoContent(false);
		}
		// console.log(data);
	};

	const handleFinish = () => {
		setShowModal((prev) => {
			return { ...prev, view: false, status: false };
		});
		setRefreshTable(!refreshTable);
	};

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'disputes'
		)?.includes('view_disputes')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Manage all Disputes made on the platform"
					pageTitle="Disputes"
					topRightContent={<></>}
				>
					{noContent && all_disputes?.data?.length < 1 ? (
						<>
							<EmptyScreen
								loading={loading}
								// loadingText={
								// 	searchLoading ? 'Searching...' : 'Loading Transactions...'
								// }
								title="No Disputes for now"
								subTitle="Disputes created on the platform get to show up here. There are none for now."
							/>
						</>
					) : (
						<>
							{/* dispute content start */}
							<div style={{marginBottom: "1.5rem"}} className="global-search-pagination-filter-box">
								<SmartFilter
									searchTitle="Search merchant email"
									defaultFilterBy="status"
									filters={[
										{
											label: 'Status',
											filterBy: 'status',
											options: [
												{
													label: 'Pending',
													value: '0',
												},
												{
													label: 'Review',
													value: '3',
												},
												{
													label: 'Discarded',
													value: '2',
												},
												{
													label: 'Resolved',
													value: '4',
												},
											],
										},
									]}
									onExport={() => setShowExport(true)}
									onSearchChange={debounce((e) => {
										dispatch(
											bankboxAPI.getAllDisputes({
												per_page: 20,
												page: currentPage,
												search: e,
											})
										);
									}, 500)}
									page="disputes"
								/>
								<div className="pagination-wrap">
									{' '}
									<Pagination
										className="top-bar__pagination"
										currentPage={currentPage}
										itemsPerPage={all_disputes?.pagination?.per_page}
										totalItems={all_disputes?.pagination?.total}
										onPageChange={function (page: number): void {
											setCurrentPage(page);
										}}
										// 			currentPage={currentPage}
										// itemsPerPage={all_bankbox_trx?.perPage ?? 0}
										// totalItems={all_bankbox_trx?.total ?? 0}
									/>
								</div>
							</div>
							<div
								// onClick={() => {
								// 	console.log(all_disputes);
								// }}
								className="dispute-dashboard-content-index-wrap"
							>
								{/* table content start */}
								<div
									onClick={() => {
										// console.log(all_disputes);
										// console.log(all_disputes?.pagination?.per_page);
									}}
									className="transaction__table non-mobile"
								>
									<RavenTable
										action={false}
										className="table__main"
										headerList={[
											'MERCHANT',
											'Type',
											'NOTE',
											'AMOUNT',
											'Status',
											'DATE LOGGED',
											// ""
										]}
									>
										{all_disputes?.data?.map((chi: any, idx: any) => {
											const {
												amount,
												date_of_dispute,
												email,
												note,
												status,
												type,
												status_type,
											} = chi || {};
											return (
												<RavenTableRow
													onRowClick={() => {
														setSingleChild(chi);
														setShowModal((prev) => {
															return { ...prev, view: true };
														});
													}}
													loading={loading}
													one={email || '---'}
													two={lowerCaseWrap(type)}
													three={trimLongString(
														capitalizeFirstLetter(note),
														12
													)}
													key={idx}
													four={
														symbol('ngn') +
														formatNumWithComma(chi?.amount, 'ngn')
													}
													// four={amount}
													five={
														<BadgeComponent
															className={checkDisputeStatusColor(status)}
															text={trimLongString(
																String(
																	checkDisputeStatus(status) === 'discard'
																		? 'discarded'
																		: checkDisputeStatus(status)
																),
																10
															)}
														/>
													}
													// six={formatDateTime(date_of_dispute)}
													six={<DateFragmentBox date={date_of_dispute} />}
												/>
											);
										})}
									</RavenTable>
								</div>
								{/* table content end */}
							</div>
							{/* dispute content end */}
						</>
					)}
				</PageLayout>
			</DashboardLayout>

			{/* view detail modal start */}
			<DisputeDetailModal
				onCancel={() => {
					setShowModal((prev) => {
						return { ...prev, view: false };
					});
				}}
				visible={showModal?.view}
				detail={singleChild}
				onFinish={handleFinish}
				onStatus={() => {
					setShowModal((prev) => {
						return { ...prev, status: true, view: false };
					});
				}}
			/>
			{/* view detail modal end */}
			{/* view detail modal start */}
			<StatusUpdateModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, status: false, view: true };
					});
				}}
				visible={showModal?.status}
				detail={singleChild}
				onFinish={handleFinish}
			/>
			{/* view detail modal end */}
			<ExportModal
				onClose={() => setShowExport(false)}
				visible={showExport}
				page="disputes"
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, "_blank")?.focus();
				}}
			/>
		</>
	);
};

export default DisputeIndexPage;
