import React, { useEffect, useState } from 'react';
import './styles/TeamSettingsIndex.css';
import SettingsLayout from '../SettingsLayout';
import SmartFilter from '../../../../../components/common/smartFilter';
import Pagination from '../../../../../components/common/pagination';
import {
	RavenButton,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../../assets/icons';
import { FaRegEye } from 'react-icons/fa';
import AddTeamModal from './AddTeamModal';
import RemoveMemberModal from './RemoveMemberModal';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '../../../../../redux/types';
import { bankboxAPI } from '../../../../../redux/bankbox';
import NoContentBox from '../../components/no-content/NoContentBox';
import {
	capitalizeFirstLetter,
	checkIfAdminOwner,
	getActionNamesByPolicyName,
	movePendingToEnd,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';
import ManageTeamModal from './UpdateMemberModal';
import ResendMemberModal from './ResendInviteModal';
import EmptyScreen from '../../../../../components/common/emptyScreen';

const TeamSettingsIndex = () => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);
	const { roles, teams, loading_teams } = useSelector(
		(state: RootState) => state.bankbox
	);
	type modalProps = {
		add: boolean;
		manage: boolean;
		delete: boolean;
		resend: boolean;
	};
	const [singleChild, setSingleChild] = useState({});
	const [showModal, setShowModal] = useState<modalProps>({
		add: false,
		delete: false,
		manage: false,
		resend: false,
	});
	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	const [refreshTable, setRefreshTable] = useState(false);
	useEffect(() => {
		dispatch(bankboxAPI?.getAllTeams({ per_page: 20, page: currentPage }));
	}, [currentPage, refreshTable]);

	useEffect(() => {
		if (Object?.keys(teams)?.length < 1) {
			dispatch(bankboxAPI?.getAllTeams({ per_page: 20, page: currentPage }));
		}

		if (roles?.length < 1) {
			dispatch(bankboxAPI?.getRoles({}));
		}
	}, []);

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				{/* {chi?.status === 1 && ( */}

				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'team invite'
				)?.includes('edit_team_member') && (
					<div
						style={{
							opacity: '1',
							cursor: 'pointer',
						}}
						onClick={() => {
							setSingleChild(chi);
							setShowModal((prev) => {
								return { ...prev, manage: true };
							});
						}}
						className="more_item"
					>
						<figure>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								fill="none"
								viewBox="0 0 24 24"
								className="img"
							>
								<path
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="1.2"
									d="M21 18l-1 1.094c-.53.58-1.25.906-2 .906a2.71 2.71 0 01-2-.906 2.716 2.716 0 00-2-.904c-.75 0-1.469.325-2 .904M3 20h1.675c.489 0 .733 0 .963-.055a2 2 0 00.579-.24c.201-.123.374-.296.72-.642L19.5 6.5a2.121 2.121 0 10-3-3L3.937 16.063c-.346.346-.519.519-.642.72-.11.18-.19.375-.24.579-.055.23-.055.475-.055.964V20z"
								></path>
							</svg>
						</figure>
						<p>Manage Member</p>
					</div>
				)}
				{chi?.invitation_Status === `Pending` &&
					getActionNamesByPolicyName(
						profile?.rolePolicies?.policies,
						'team invite'
					)?.includes('invite_team_member') && (
						<>
							<div
								style={{
									opacity: '1',
									cursor: 'pointer',
								}}
								onClick={() => {
									setSingleChild(chi);
									setShowModal((prev) => {
										return { ...prev, resend: true };
									});
								}}
								className="more_item"
							>
								<figure>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										fill="none"
										viewBox="0 0 24 24"
										className="img"
									>
										<path
											stroke="#000"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 00-2.667 2.667C2 18.907 2 19.604 2 21m17 0v-6m-3 3h6M14.5 7.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z"
										></path>
									</svg>
								</figure>
								<p>Resend Invite</p>
							</div>
						</>
					)}
				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'team invite'
				)?.includes('remove_team_member') &&
					!checkIfAdminOwner(chi?.role) && (
						<div
							style={{
								opacity: '1',
								cursor: 'pointer',
							}}
							onClick={() => {
								setShowModal((prev) => {
									return { ...prev, delete: true };
								});
								setSingleChild(chi);
							}}
							className="more_item"
						>
							<figure>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									fill="none"
									viewBox="0 0 24 24"
									className="img"
								>
									<path
										stroke="#FF0F00"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.2"
										d="M9 3h6M3 6h18m-2 0l-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 01-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 016.2 19.485c-.34-.598-.394-1.387-.499-2.966L5 6m5 4.5v5m4-5v5"
									></path>
								</svg>
							</figure>
							<p style={{ color: '#FF0F00' }}>Remove Member</p>
						</div>
					)}
			</div>
		);
	}

	return (
		<>
			<SettingsLayout>
				<div className="team-settings-index-wrap-box">
					{loading_teams && Object?.keys(teams)?.length < 1 ? (
						<>
							<NoContentBox
								loading={loading_teams}
								title="No Team member recorded yet"
								text="Team member invited on the platform get to show up here. There are none for now."
								btnText="Add Member"
								onBtnClick={() => {
									setShowModal((prev) => {
										return { ...prev, add: true };
									});
									// console.log(teams);
								}}
							/>
						</>
					) : (
						<>
							{' '}
							{/* top select part start */}
							<div className="top-select-box">
								<SmartFilter
									hideExport
									searchTitle="Search Team Member.."
									defaultFilterBy="status"
									filters={[
										{
											label: 'Status',
											filterBy: 'status',
											options: [
												{
													label: 'Pending',
													value: '0',
												},
												{
													label: 'Accepted',
													value: '4',
												},
											],
										},
									]}
									onExport={() => {}}
									onSearchChange={() => {}}
									// page="disputes"
								/>
								<div className="settings-pagination-box border-theme">
									<Pagination
										className="top-bar__pagination"
										currentPage={currentPage}
										itemsPerPage={teams?.pagination?.perPage || 10}
										totalItems={teams?.pagination?.total}
										onPageChange={function (page: number): void {
											setCurrentPage(page);
										}}
									/>
								</div>

								{getActionNamesByPolicyName(
									profile?.rolePolicies?.policies,
									'team invite'
								)?.includes('invite_team_member') && (
									<RavenButton
										label="Add Member"
										className="btn"
										color="deep-green-light"
										onClick={() => {
											// console.log(teams);

											setShowModal((prev) => {
												return { ...prev, add: true };
											});
										}}
									/>
								)}
							</div>
							{/* top select part end */}
							{/* table start */}
							<div
								// onClick={() => {
								// 	console.log(movePendingToEnd(teams?.data));
								// }}
								className="table-setting-body"
							>
								<RavenTable
									headerList={[
										'MEMBER NAME',
										'EMAIL ADDRESS',
										'ROLE',
										'DATE JOINED',
										'STATUS',
										'ACTION',
									]}
								>
									{teams?.data?.length > 0 &&
										movePendingToEnd(teams?.data)?.map((chi: any, idx: any) => {
											return (
												<RavenTableRow
													className={
														chi?.invitation_Status !== 'Accepted'
															? `pending-row`
															: ''
													}
													loading={loading_teams}
													key={idx}
													one={
														chi?.first_name?.length + chi?.last_name?.length <
														25
															? `${
																	capitalizeFirstLetter(chi?.first_name) || ''
															  } ${chi?.last_name}`
															: `${chi?.first_name} ${trimLongString(
																	chi?.last_name,
																	4
															  )}`
													}
													two={trimLongString(chi?.email, 30) || ''}
													three={
														<BadgeComponent
															className={`pending ${
																chi?.role === 'administrator'
																	? 'purple'
																	: chi?.role === 'owner'
																	? `success`
																	: chi?.role === 'support'
																	? `pending`
																	: chi?.role === 'finance'
																	? `info`
																	: 'pending'
															}`}
															text={trimLongString(chi?.role, 25)}
														/>
													}
													four={
														chi?.invitation_Status === 'Accepted' ? (
															<DateFragmentBox date={chi?.joined_at} />
														) : (
															'---'
														)
													}
													five={
														chi?.invitation_Status === 'Accepted' ? (
															<BadgeComponent
																className="success"
																text={`Active`}
															/>
														) : (
															<BadgeComponent
																className="pending"
																text={chi?.invitation_Status}
															/>
														)
													}
													six={
														getActionNamesByPolicyName(
															profile?.rolePolicies?.policies,
															'team invite'
														)?.includes('edit_team_member') ||
														getActionNamesByPolicyName(
															profile?.rolePolicies?.policies,
															'team invite'
														)?.includes('remove_team_member') ||
														getActionNamesByPolicyName(
															profile?.rolePolicies?.policies,
															'team invite'
														)?.includes('invite_team_member') ? (
															<div
																style={{ position: 'relative' }}
																onClick={(e) => {
																	e.stopPropagation();
																	setShowDrop((prev) => {
																		return {
																			...prev,
																			id: chi?.id,
																			show:
																				showDrop?.id === chi?.id
																					? !showDrop.show
																					: true,
																		};
																	});
																}}
															>
																<DropMore
																	idx={chi?.id}
																	className={isDropped(chi?.id) && 'show-drop'}
																	chi={chi}
																/>
																{icons.three_dots}
															</div>
														) : (
															<></>
														)
													}
												/>
											);
										})}
								</RavenTable>
							</div>
							{/* table end */}
						</>
					)}
				</div>
			</SettingsLayout>
			{/* modal start */}
			<AddTeamModal
				visible={showModal.add}
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, add: false };
					});
				}}
				onFinish={() => {
					dispatch(
						bankboxAPI?.getAllTeams({ per_page: 20, page: currentPage })
					);
					setShowModal((prev) => {
						return { ...prev, add: false };
					});
				}}
			/>
			<ManageTeamModal
				visible={showModal.manage}
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, manage: false };
					});
				}}
				detail={singleChild}
				onFinish={() => {
					dispatch(
						bankboxAPI?.getAllTeams({ per_page: 20, page: currentPage })
					);
					setShowModal((prev) => {
						return { ...prev, manage: false };
					});
				}}
			/>
			<RemoveMemberModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, delete: false };
					});
				}}
				visible={showModal?.delete}
				details={singleChild}
				onFinish={() => {
					setRefreshTable(!refreshTable);
					setShowModal((prev) => {
						return { ...prev, delete: false };
					});
				}}
			/>
			<ResendMemberModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, resend: false };
					});
				}}
				visible={showModal?.resend}
				details={singleChild}
				onFinish={() => {
					// setRefreshTable(!refreshTable)
					setShowModal((prev) => {
						return { ...prev, resend: false };
					});
				}}
			/>
			{/* modal end */}
		</>
	);
};

export default TeamSettingsIndex;
