import { RavenInputField, RavenModal } from '@ravenpay/raven-bank-ui';
import React, { useEffect } from 'react';
import { icons } from '../../../assets/icons';
import './style/index.css';
import {
	exportAirtime,
	exportBetting,
	exportCable,
	exportCollections,
	exportCustomers,
	exportData,
	exportElectricity,
	exportTransactions,
	exportTransfers,
	exportVirtualAccount,
} from '../../../redux/export';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import {
	capitalizeFirstLetter,
	formatDate,
} from '../../../utils/helper/Helper';
import { logger } from '../../../services/logger';
import { bankboxAPI } from '../../../redux/bankbox';
import ImageReader from '../cameraTest/CameraTest';

const days = ['Today', 'Custom'];

const EODExportModal = ({
	visible,
	onClose,
	onClick,
	onChange,
	value,
	page = 'wallet',
}: {
	visible: boolean;
	onClose?: () => void;
	onClick?: () => void;
	page?: string;
	loading?: boolean;
	value?: {
		date:
			| string
			| undefined
			| {
					start_date?: string;
					end_date?: string;
			  };
		fileType: string;
	};
	onChange?: any;
}) => {
	const [selectedDay, setSelectedDay] = React.useState<
		| string
		| undefined
		| {
				start_date?: string | undefined;
				end_date?: string | undefined;
		  }
	>('Today');
	const [fileType, setFileType] = React.useState('csv');
	const [loading, setLoading] = React.useState(false);
	const [dateRange, setDateRange] = React.useState<{
		start_date?: string | undefined;
		end_date?: string | undefined;
	}>({
		start_date: '',
		end_date: '',
	});

	const dispatch = useDispatch<AppDispatch>();
	function getNthDaysAgo(d: number) {
		const today = new Date(); // Get today's date
		const nthDay = new Date(today); // Create a new date object

		nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

		return nthDay;
	}

	// function downloadFile(url: any, filename: string) {
	// 	const a = document.createElement('a');

	// 	// logger.log(url.url ?? url, 'oinu');
	// 	a.href = url.url ?? url;
	// 	a.download = filename || 'download';
	// 	const clickHandler = () => {
	// 		setTimeout(() => {
	// 			URL.revokeObjectURL(url);
	// 			removeEventListener('click', clickHandler);
	// 		}, 150);
	// 	};

	// 	a.addEventListener('click', clickHandler, false);

	// 	a.click();

	// 	return a;
	// }

	function downloadFileInNewTab(url: any, filename: string) {
		// Open a new tab with a custom title
		const newTab = window.open(url, '_blank', `title=${filename}`);

		// Focus on the new tab
		newTab?.focus();
	}

	function handleFilter(str?: string) {
		const seventhDayAgo = getNthDaysAgo(7);
		const last30Days = getNthDaysAgo(30);

		const formated30days = formatDate(last30Days);
		const formated7days = formatDate(seventhDayAgo);
		const formated14days = formatDate(getNthDaysAgo(14));

		setDateRange({
			start_date:
				str === 'All'
					? formatDate(new Date('2022-06-01'))
					: str === 'Today'
					? formatDate(new Date())
					: str === 'Last 7 Days'
					? formated7days
					: str === 'Last 14 Days'
					? formated14days
					: str === 'Last 30 Days'
					? formated30days
					: dateRange.start_date,
			end_date:
				str === 'All'
					? formatDate(addDays(new Date(), 1))
					: str === 'Today'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 7 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 14 Days'
					? formatDate(addDays(new Date(), 1))
					: str === 'Last 30 Days'
					? formatDate(addDays(new Date(), 1))
					: dateRange.end_date,
		});
	}

	async function handleExport() {
		setLoading(true);
		// handleFilter(selectedDay as string);
		let val;
		// const new
		if (selectedDay === 'Today') {
			const today = new Date();
			const year = today.getFullYear();
			const month = today.getMonth() + 1; // Months are zero-based
			const day = today.getDate();

			const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
				day < 10 ? '0' + day : day
			}`;
			const obj = {
				exportType: fileType,
				startDate: formattedDate,
				stopDate: formattedDate,
			};
			val = obj;
		} else {
			const obj = {
				exportType: fileType,
				startDate: dateRange?.start_date?.replaceAll('/', '-'),
				stopDate: dateRange?.end_date?.replaceAll('/', '-'),
			};
			val = obj;
		}

		// console.log(val);

		const data = await dispatch(bankboxAPI.exportEODBankboxes(val as any));
		// console.log(data);

		if (data?.payload?.status === 'success') {
			const url = data?.payload?.data?.data;
			setLoading(false);
			downloadFileInNewTab(url, 'EOD report file');
			onClose && onClose();
		} else {
			setLoading(false);
		}
	}

	function addDays(date: Date | string, days: number) {
		// Function to add Days
		var result = new Date(date);
		result.setDate(result.getDate() + days);

		return result;
	}

	// return(
	// 	<ImageReader />
	// )

	return (
		<RavenModal
			visble={visible}
			onClose={onClose}
			loading={loading}
			disabled={
				selectedDay === 'Today'
					? !fileType
					: selectedDay === 'Custom' &&
					  (!fileType || !dateRange?.end_date || !dateRange?.start_date)
					? true
					: false
			}
			btnLabel="Export Data"
			btnColor="deep-green-light"
			onBtnClick={handleExport}
			className={`session-expired-wrap-modal`}
		>
			{/* <ImageReader /> */}
			<div className="export-modal">
				<div className="export-modal__title">
					<h5>Generate EOD Report</h5>
					<p>Choose how you would like to export your data</p>
				</div>

				<div className="export-modal__export-as">
					<label htmlFor="">Export as</label>
					<div className={'export-modal__export-as--selector-wrap'}>
						<div
							onClick={() => {
								setFileType('csv');
								onChange && onChange({ ...value, fileType: 'csv' });
							}}
							className={`selector-wrap__item ${
								fileType === 'csv' && 'selected'
							}`}
						>
							<figure>
								{fileType === 'csv' ? icons.radio_check : icons.radio_unchecked}
							</figure>
							<p>CSV File</p>
						</div>
						<div
							onClick={() => {
								setFileType('pdf');
								onChange && onChange({ ...value, fileType: 'pdf' });
							}}
							className={`selector-wrap__item ${
								fileType === 'pdf' && 'selected'
							}`}
						>
							<figure>
								{fileType === 'pdf' ? icons.radio_check : icons.radio_unchecked}
							</figure>
							<p>PDF File</p>
						</div>
					</div>

					<div className="export-modal__days-selector">
						<label htmlFor="">Date</label>

						<div className={`days-selector__item-wrap `}>
							{days?.map((d, i) => {
								return (
									<div
                  key={i}
										onClick={() => {
											if (d === 'Today') {
												setDateRange((prev) => {
													return {
														...prev,
														end_date: '',
														start_date: '',
													};
												});
											}
											setSelectedDay(d);
											// handleFilter(d as string);
											// onChange && onChange({ ...value, date: d });
										}}
										className={`days-selector__item ${
											selectedDay === d && 'selected'
										}`}
									>
										<p>{d}</p>
										{selectedDay === d && 'selected' && (
											<figure>{icons.green_checkmark}</figure>
										)}
									</div>
								);
							})}
						</div>
					</div>

					<div
						className={`export-modal__custom-date ${
							selectedDay === 'Custom' && 'show'
						}`}
					>
						<RavenInputField
							color="deep-green-dark"
							label="From"
							placeholder="Select a Date"
							type="date"
							dateOptions={{ maxDate: new Date() }}
							value={dateRange?.start_date}
							onChange={(e: string) =>
								setDateRange((prev) => {
									return { ...prev, start_date: formatDate(new Date(e)) };
								})
							}
						/>
						<RavenInputField
							value={dateRange?.end_date}
							color="deep-green-dark"
							label="To"
							placeholder="Select a Date"
							type="date"
							dateOptions={{
								maxDate: new Date(),
								minDate: dateRange?.start_date,
							}}
							onChange={(e: string) =>
								setDateRange((prev) => {
									return { ...prev, end_date: formatDate(new Date(e)) };
								})
							}
						/>
					</div>
				</div>
			</div>
		</RavenModal>
	);
};

export default EODExportModal;
