import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../../layout/dashboard/tableLayout';
import {
	RavenButton,
	RavenInputField,
	RavenModal,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import SmartFilter from '../../../../../components/common/smartFilter';
import { brands, icons } from '../../../../../assets/icons';
import visa from '../../../../../assets/images/visa.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/common/pagination';
import EmptyScreen from '../../../../../components/common/emptyScreen';
import Skeleton from 'react-loading-skeleton';
import {
	capitalizeAllLetter,
	formatDateTime,
	formatNumWithComma,
	getActionNamesByPolicyName,
	insertUrlParam,
	returnInitial,
	symbol,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { generateRandomColor } from '../../../../../utils/helper/color';
import MobileTableCard from '../../../../../components/common/table/mobile/MobileTable';
import '../styles/index.css';
import { FaEye, FaRegEye } from 'react-icons/fa';
import RootState, {
	AllBankboxResponse,
	AssignBankBoxPayload,
	BankBoxData,
	BankBoxUser,
} from '../../../../../redux/types';
import DashboardLayout from '../../../../../layout/dashboard/dashboardLayout';
import { getAllCards } from '../../../../../redux/issuing';
import { bankboxAPI } from '../../../../../redux/bankbox';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
import { debounce } from 'lodash';
import NoContentBox from '../../components/no-content/NoContentBox';
import DateFragmentBox from '../../../../../components/common/DateFragmentBox';

const AllBankBox = ({ onExport }: any) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [showDeactivate, setShowDeactivate] = useState<{
		on: boolean;
		chi: BankBoxData;
	}>({
		on: false,
		chi: {} as BankBoxData,
	});
	const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
	const [singleChild, setSingleChild] = useState<any>({});
	const [assignFormData, setAssignFormData] = useState<AssignBankBoxPayload>({
		// affiliate_app_id: '',
		poseidon_business_email: '',
		serial_number: '',
		exist_nibbs: true,
		label: '',
		poseidon_business_name: '',
		// auto_gen: true,
		auto_generate: true,
		is_nibss: true,
		terminal_id: '',
	});
	const [assignBankbox, setAssignBankbox] = useState<{
		on: boolean;
		chi: BankBoxUser;
	}>({
		on: false,
		chi: {
			id: 0,
			fname: '',
			lname: '',
			poseidon_email: '',
			bvn: '',
			nin: '',
			phone: '',
			affiliate_app: '',
			affiliate_app_id: '',
			poseidon_business_name: '',
			poseidon_business_address: '',
			poseidon_business_type: null,
			poseidon_business_description: '',
			poseidon_profile_reference: '',
			business_category: '',
			region: '',
			created_at: '',
			updated_at: '',
			lga: '',
		},
	});
	function onView(e?: any) {}
	function cardSettings(e?: any) {}

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});
	const searchLoading = false;
	const {
		all_bankbox,
		loading,
		all_bankbox_data,
	}: { all_bankbox: any; loading: boolean; all_bankbox_data: [] } = useSelector(
		(state: RootState) => state.bankbox
	);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const bankboxes: AllBankboxResponse = all_bankbox;

	function formatStatus(status: string) {
		return (
			<div className={`bankbox-status ${status && status?.toLowerCase()}`}>
				<p style={{ textTransform: 'capitalize' }}>{status}</p>
			</div>
		);
	}

	// React.useEffect(() => {
	// 	dispatch(
	// 		bankboxAPI.getAllBankBox({
	// 			per_page: 20,
	// 			page: currentPage,
	// 		})
	// 	);
	// }, [currentPage]);

	const { profile } = useSelector((state: RootState) => state.settings);
	const [noDataFound, setNoDataFound] = useState<boolean>(true);
	React.useEffect(() => {
		getAllUserFirst();
	}, [currentPage]);

	const getAllUserFirst = async () => {
		const data = await dispatch(
			bankboxAPI.getAllBankBox({
				per_page: 20,
				page: currentPage,
			})
		);
		if (data?.payload?.status === 'success') {
			// console.log(data);

			const checkLength = data?.payload?.data?.data?.data;
			// console.log(checkLength);

			if (checkLength?.length > 0) {
				setNoDataFound(false);
				// console.log('ooo');
			} else {
				setNoDataFound(true);
			}
		}
	};

	// logger.log(all_bankbox);

	async function handleView(e: string) {
		// insert url param
		localStorage?.setItem('single-ter-serial', e);
		await navigate({
			pathname: '/dashboard-bankbox/terminals/view',
		});

		// append the query parameters
		// await insertUrlParam('serial', e, `Atlas: BankBox - SN:${e}`);
	}

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				{/* {chi?.status === 1 && ( */}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						onView &&
							onView({
								on: true,
								chi: chi,
							});
						handleView(chi.serial);
						setShowDrop({
							show: !showDrop.show,
							id: idx,
						});
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Terminal</p>
				</div>
				{/* )} */}
				{getActionNamesByPolicyName(
					profile?.rolePolicies?.policies,
					'terminals'
				)?.includes('delete_terminals') && (
					<>
						<div
							style={{
								opacity: '1',  
								cursor: 'pointer',
							}}
							onClick={() => {
								setShowDeactivate({
									on: true,
									chi: chi,
								});
							}}
							className="more_item"
						>
							<figure style={{ width: '2.5rem', height: '2.5rem' }}>
								{chi?.status === 1 ? (
									icons.deactivate_terminal
								) : (
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="img"
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'contain',
										}}
									>
										<circle cx="12" cy="12" r="12" fill="#F7F7F7" />
										<path
											d="M13.5 7V7.7C13.5 7.98003 13.5 8.12004 13.4455 8.227C13.3976 8.32108 13.3211 8.39757 13.227 8.4455C13.12 8.5 12.98 8.5 12.7 8.5H11.3C11.02 8.5 10.88 8.5 10.773 8.4455C10.6789 8.39757 10.6024 8.32108 10.5545 8.227C10.5 8.12004 10.5 7.98003 10.5 7.7V7M10.1 17H13.9C14.4601 17 14.7401 17 14.954 16.891C15.1422 16.7951 15.2951 16.6422 15.391 16.454C15.5 16.2401 15.5 15.9601 15.5 15.4V8.6C15.5 8.03995 15.5 7.75992 15.391 7.54601C15.2951 7.35785 15.1422 7.20487 14.954 7.10899C14.7401 7 14.4601 7 13.9 7H10.1C9.53995 7 9.25992 7 9.04601 7.10899C8.85785 7.20487 8.70487 7.35785 8.60899 7.54601C8.5 7.75992 8.5 8.03995 8.5 8.6V15.4C8.5 15.9601 8.5 16.2401 8.60899 16.454C8.70487 16.6422 8.85785 16.7951 9.04601 16.891C9.25992 17 9.53995 17 10.1 17Z"
											stroke="#014345"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								)}
							</figure>
							<p>
								{chi?.status === 1 ? 'Deactivate Terminal' : 'Activate BankBox'}
							</p>
						</div>
						<div
							style={{
								opacity: '1',
								cursor: 'pointer',
							}}
							onClick={() => {
								setShowModalDelete(true);
								setSingleChild(chi);
								// console.log(chi);
							}}
							className="more_item"
						>
							<figure>
								{
									<svg
										className="img"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5"
											stroke="#FF0F00"
											strokeWidth="1.2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								}
							</figure>
							<p style={{ color: '#FF0F00' }}>Delete Device</p>
						</div>
					</>
				)}
			</div>
		);
	}

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	useEffect(() => {
		setAssignFormData({
			...assignFormData,
			poseidon_business_email: assignBankbox?.chi?.poseidon_email,
			poseidon_business_name: assignBankbox?.chi?.poseidon_business_name,
		});
	}, [assignBankbox]);

	async function handleAssign() {
		const resp = await dispatch(bankboxAPI.assignBankbox(assignFormData));

		if (resp.payload.status === 'success') {
			setAssignBankbox({
				chi: {
					id: 0,
					fname: '',
					lname: '',
					poseidon_email: '',
					bvn: '',
					nin: '',
					phone: '',
					affiliate_app: '',
					affiliate_app_id: '',
					poseidon_business_name: '',
					poseidon_business_address: '',
					poseidon_business_type: null,
					poseidon_business_description: '',
					poseidon_profile_reference: '',
					business_category: '',
					region: '',
					created_at: '',
					updated_at: '',
					lga: '',
				},
				on: false,
			});
		}
	}

	async function handleDeactivate() {
		const resp = await dispatch(
			bankboxAPI.deactivateBankbox({ serial_no: showDeactivate?.chi?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowDeactivate({
				on: false,
				chi: {} as BankBoxData,
			});

			dispatch(
				bankboxAPI.getAllBankBox({
					per_page: 20,
					page: currentPage,
				})
			);
		}
	}
	const [loadingDelete, setLoadingDelete] = useState(false);
	async function handleDeleteTerminal() {
		setLoadingDelete(true);
		const resp = await dispatch(
			bankboxAPI.deleteBankbox({ serial_no: singleChild?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowModalDelete(false);
			setSingleChild({});
			setLoadingDelete(false);
			dispatch(
				bankboxAPI.getAllBankBox({
					per_page: 20,
					page: currentPage,
				})
			);
		} else {
			setLoadingDelete(false);
		}
	}

	async function handleReactivate() {
		const resp = await dispatch(
			bankboxAPI.reactivateBankbox({ serial_no: showDeactivate?.chi?.serial })
		);

		if (resp.payload.status === 'success') {
			setShowDeactivate({
				on: false,
				chi: {} as BankBoxData,
			});

			dispatch(
				bankboxAPI.getAllBankBox({
					per_page: 20,
					page: currentPage,
				})
			);
		}
	}

	// console.log(bankboxes);
	return (
		<>
			<>
				<PageLayout
					pageSubtitle="All BankBox created on the platform"
					pageTitle="BankBox Terminals"
					topRightContent={<></>}
				>
					{!noDataFound && (
						<div
							onClick={() => {
								setShowDrop({
									show: false,
									id: '',
								});
							}}
							className="global-search-pagination-filter-box"
						>
							{' '}
							<SmartFilter
								defaultFilterBy="status"
								onSearchChange={debounce((e) => {
									dispatch(
										bankboxAPI.getAllBankBox({
											per_page: 20,
											page: currentPage,
											search: e,
										})
									);
								}, 500)}
								onExport={onExport}
								page="bankbox_terminals"
								searchTitle="Search by serial no"
								filters={[
									{
										label: 'Status',
										filterBy: 'status',
										options: [
											{
												label: 'Activated',
												value: '1',
											},
											{
												label: 'Deactivated',
												value: '0',
											},
											{
												label: 'All',
												value: 'reset',
											},
										],
									},
								]}
							/>
							<Pagination
								className="top-bar__pagination"
								currentPage={currentPage}
								itemsPerPage={all_bankbox?.pagination?.per_page}
								totalItems={all_bankbox?.pagination?.total}
								onPageChange={function (page: number): void {
									setCurrentPage(page);
								}}
							/>
						</div>
					)}
					{noDataFound || all_bankbox_data?.length < 1 ? (
						<>
							{' '}
							<EmptyScreen
								loading={searchLoading || loading}
								loadingText={
									searchLoading ? 'Searching...' : 'Loading Bankboxes...'
								}
								title="No BankBox Found"
								subTitle="You don't have any record yet, create new bankbox requests and have them appear here."
							/>
						</>
					) : (
						<>
							{' '}
							<div
								// onClick={() => {
								// 	console.log(all_bankbox_data);
								// 	console.log(all_bankbox);
								// }}
								onClick={() => {
									setShowDrop({
										show: false,
										id: '',
									});
								}}
								className="content__main issuing"
							>
								<div
									onClick={() => {
										setShowDrop({
											show: false,
											id: '',
										});
									}}
									className="main__wrap"
								>
									<div className="transaction__table non-mobile">
										<RavenTable
											action={false}
											className="table__main"
											headerList={[
												'SERIAL NO',
												'BUSINESS',
												'EMAIL ADDRESS',
												'DATE DISBURSED',
												'STATUS',
												'ACTION',
											]}
										>
											{bankboxes?.data?.map((chi, idx: any) => {
												return (
													<React.Fragment key={idx}>
														<RavenTableRow
															onRowClick={() => {
																// console.log(chi);
																onView &&
																	onView({
																		on: true,
																		chi: chi,
																	});
																handleView(chi.serial);
																setShowDrop({
																	show: !showDrop.show,
																	id: idx,
																});
															}}
															// manualActionIcon={icons.logout}
															className="table__main"
															five={
																loading ? (
																	<Skeleton
																		style={{ borderRadius: '10px' }}
																		width={80}
																		height={20}
																	/>
																) : (
																	formatStatus(
																		chi.status === 1
																			? 'Activated'
																			: 'Deactivated'
																	)
																)
															}
															six={
																<div
																	style={{ position: 'relative' }}
																	onClick={(e) => {
																		e.stopPropagation();
																		setShowDrop((prev) => {
																			return {
																				...prev,
																				id: idx,
																				show:
																					showDrop?.id === idx
																						? !showDrop.show
																						: true,
																			};
																		});
																	}}
																>
																	<DropMore
																		idx={idx}
																		className={isDropped(idx) && 'show-drop'}
																		chi={chi}
																	/>
																	{icons.three_dots}
																</div>
															}
															four={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	// formatDateTime(chi?.created_at)
																	<DateFragmentBox date={chi?.created_at} />
																)
															}
															one={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	<div className="table__card-pan">
																		<p>{chi?.serial}</p>
																	</div>
																)
															}
															two={
																<div className="account_name--transfer">
																	{loading ? (
																		<Skeleton circle width={35} height={35} />
																	) : (
																		<span
																			className="account_name__span"
																			style={{
																				backgroundColor:
																					generateRandomColor('#e9f5ff'),
																			}}
																		>
																			<p>
																				{returnInitial(
																					chi?.poseidon_business_name ?? ''
																				)}
																			</p>
																		</span>
																	)}

																	{loading ? (
																		<Skeleton width={230} height={20} />
																	) : (
																		<p className="text">
																			{' '}
																			{capitalizeAllLetter(
																				chi?.poseidon_business_name
																			)}
																		</p>
																	)}
																</div>
															}
															three={
																loading ? (
																	<Skeleton width={100} height={20} />
																) : (
																	chi?.poseidon_business_email
																)
															}
														/>
													</React.Fragment>
												);
											})}
										</RavenTable>
									</div>

									<div className="atlas-mobile-table-wrap">
										{bankboxes?.data?.map(
											(chi, idx: React.Key | null | undefined) => {
												// const { first_name, last_name, bank } = meta_data;

												// logger.log(meta_data);
												return (
													<MobileTableCard
														key={idx}
														title={chi?.poseidon_business_email}
														text={chi?.serial}
														amount={`Volume: 
																${symbol('ngn') + formatNumWithComma(chi.volume, 'ngn')}`}
														img={visa}
														svg={undefined}
														avatar={''}
														amountColor={'#014345'}
														type={''}
														showImg={true}
														loading={loading}
														onRowClick={() =>
															onView &&
															onView({
																on: true,
																chi: chi,
															})
														}
														bottomRightText={formatDateTime(chi.created_at)}
													/>
												);
											}
										)}
									</div>
								</div>
							</div>
						</>
					)}
				</PageLayout>
			</>

			<RavenModal
				onClose={() => setAssignBankbox({ ...assignBankbox, on: false })}
				visble={assignBankbox.on}
				loading={loading}
				onBtnClick={handleAssign}
				btnColor="deep-green-light"
				btnLabel="Assign"
			>
				<div className="assign-bb-modal">
					<div className="assign-bb-modal__title">
						<h6>Assign BankBox</h6>
						<p>Allocate a BankBox to a user</p>
					</div>

					<div className="assign-bb-modal__form-body">
						<RavenInputField
							type="text"
							color="deep-green-light"
							label="BankBox Serial Number"
							onChange={(e: any) => {
								setAssignFormData({
									...assignFormData,
									serial_number: e.target.value,
								});
							}}
							placeholder="4989q3****"
							name="serial"
						/>
						<RavenInputField
							type="text"
							onChange={(e: any) => {
								setAssignFormData({
									...assignFormData,
									label: e.target.value,
								});
							}}
							color="deep-green-light"
							label="BankBox Label"
							placeholder="cp-11-finance"
							name="serial"
						/>
					</div>
				</div>
			</RavenModal>
			<DeleteModal
				onDelete={
					showDeactivate?.chi?.status === 1
						? handleDeactivate
						: handleReactivate
				}
				loading={loading}
				deleteText={
					showDeactivate.chi?.status === 1 ? 'Deactivate' : 'Activate'
				}
				buttonColor={showDeactivate.chi?.status !== 1 ? 'deep-green-light' : ''}
				visible={showDeactivate.on}
				onCancel={() =>
					setShowDeactivate({
						on: false,
						chi: {} as BankBoxData,
					})
				}
				title={
					showDeactivate.chi?.status === 1
						? 'Deactivate BankBox'
						: 'Activate BankBox'
				}
				text={
					showDeactivate.chi?.status === 1
						? 'Deactivating a BankBox suspend it from recieving or sending out money, are you sure you want to proceed?'
						: 'Activating this BankBox will activate the sending and receiving feature of the BankBox.'
				}
			/>
			<DeleteModal
				onDelete={handleDeleteTerminal}
				loading={loadingDelete}
				deleteText={`Delete Device`}
				buttonColor={''}
				visible={showModalDelete}
				onCancel={() => setShowModalDelete(false)}
				title={`Delete Device ( ${trimLongString(singleChild?.serial, 15)} )`}
				text={`Deleting a BankBox remove it from recieving or sending out money, are you sure you want to proceed?`}
			/>
		</>
	);
};

export default AllBankBox;
