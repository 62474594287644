import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import PageLayout from '../../../../layout/dashboard/tableLayout';
import EmptyScreen from '../../../../components/common/emptyScreen';
import './style/FeeDetailIndexPage.css';
import SmartFilter from '../../../../components/common/smartFilter';
import Pagination from '../../../../components/common/pagination';
import {
	RavenButton,
	RavenTable,
	RavenTableRow,
} from '@ravenpay/raven-bank-ui';
import { icons } from '../../../../assets/icons';
import CreateFeeCategoryModal from './CreateFeeCategoryModal';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../redux/bankbox';
import RootState from '../../../../redux/types';
import {
	capitalizeFirstWord,
	formatDateTime,
	formatNumWithComma,
	formatTypeFunction,
	getActionNamesByPolicyName,
	symbol,
} from '../../../../utils/helper/Helper';
import { debounce } from 'lodash';
import ExportModal from '../../../../components/common/exportModal';
import DateFragmentBox from '../../../../components/common/DateFragmentBox';
import { FaRegEye } from 'react-icons/fa';
import ViewFeeCategoryModal from './ViewFeeModal';
import EditFeeCategoryModal from './EditFeeCategoryModal';

const FeeDetailsIndexPage = () => {
	const [noContent, setNoContent] = useState(true);
	type showModalProp = {
		create: boolean;
		export: boolean;
		view_fee: boolean;
		edit_fee: boolean;
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState<showModalProp>({
		create: false,
		export: false,
		view_fee: false,
		edit_fee: false,
	});
	const dispatch = useDispatch();
	const [refreshTable, setRefreshTable] = useState(false);
	const { all_fees, loading } = useSelector(
		(state: RootState) => state.bankbox
	);

	useEffect(() => {
		getFirstFetch();
	}, [refreshTable, currentPage]);

	const getFirstFetch = async () => {
		const data = await dispatch(
			bankboxAPI.getAllFees({
				per_page: 20,
				page: currentPage,
				// affiliate_app_id:
			})
		);

		if (
			data?.payload?.status === 'success' &&
			data?.payload?.data?.data?.data?.length > 0
		) {
			setNoContent(false);
		}
		// console.log(data);
	};

	const handleFinish = () => {
		setRefreshTable(!refreshTable);
		setShowModal((prev) => {
			return {
				...prev,
				create: false,
				export: false,
				view_fee: false,
				edit_fee: false,
			};
		});
	};

	const [singleChild, setSingleChild] = useState<any>({});
	// const [showModal, setShowModal] = useState({

	// })

	const editIcon = (
		<svg
			className="img"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21 18.0002L19.9999 19.0943C19.4695 19.6744 18.7501 20.0002 18.0001 20.0002C17.2501 20.0002 16.5308 19.6744 16.0004 19.0943C15.4692 18.5154 14.75 18.1903 14.0002 18.1903C13.2504 18.1903 12.5311 18.5154 12 19.0943M3 20.0002H4.67454C5.16372 20.0002 5.40832 20.0002 5.63849 19.945C5.84256 19.896 6.03765 19.8152 6.2166 19.7055C6.41843 19.5818 6.59138 19.4089 6.93729 19.063L19.5 6.50023C20.3285 5.6718 20.3285 4.32865 19.5 3.50023C18.6716 2.6718 17.3285 2.6718 16.5 3.50023L3.93726 16.063C3.59136 16.4089 3.4184 16.5818 3.29472 16.7837C3.18506 16.9626 3.10425 17.1577 3.05526 17.3618C3 17.5919 3 17.8365 3 18.3257V20.0002Z"
				stroke="black"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
				className=""
			/>
		</svg>
	);

	function DropMore({ className, idx, chi }: any) {
		return (
			<div className={`more-wrap ${className}`}>
				{/* {chi?.status === 1 && ( */}
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						// console.log(chi);

						setSingleChild(chi);
						setShowModal((prev) => {
							return { ...prev, view_fee: true };
						});
					}}
					className="more_item"
				>
					<figure>
						<FaRegEye />
					</figure>
					<p>View Fee</p>
				</div>
				<div
					style={{
						opacity: '1',
						cursor: 'pointer',
					}}
					onClick={() => {
						setSingleChild(chi);
						setShowModal((prev) => {
							return { ...prev, edit_fee: true };
						});
					}}
					className="more_item"
				>
					<figure className="img-box">{editIcon}</figure>
					<p>Edit Fee</p>
				</div>
				{/* )} */}
			</div>
		);
	}

	const [showDrop, setShowDrop] = useState<{
		show: boolean;
		id: number | string;
	}>({
		show: false,
		id: '',
	});

	function isDropped(idx: number | string) {
		if (showDrop.id === idx && showDrop.show) return true;
		else return false;
	}

	const { profile } = useSelector((state: RootState) => state.settings);
	if (
		!getActionNamesByPolicyName(
			profile?.rolePolicies?.policies,
			'fee details'
		)?.includes('view_fee_details')
	) {
		return (
			<DashboardLayout>
				<>
					<EmptyScreen
						loading={Object?.keys(profile)?.length < 1 ? true : false}
						title="Permission Denied"
						subTitle="You have no permission to access this page, Please contact admin or support."
					></EmptyScreen>
				</>
			</DashboardLayout>
		);
	}

	

	return (
		<>
			<DashboardLayout>
				<PageLayout
					pageSubtitle="Manage Fees on your terminals"
					pageTitle="Fee Details"
					topRightContent={
						noContent ? (
							<></>
						) : (
							<>
								<RavenButton
									size="small"
									onClick={() => {
										setShowModal((prev) => {
											return { ...prev, create: true };
										});
									}}
									color="deep-green-light"
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '.4rem',
										}}
										className="button__wrap"
									>
										<span>Create Fee </span>
										<figure>{icons.plus_circle_white}</figure>
									</div>
								</RavenButton>
							</>
						)
					}
				>
					{noContent ? (
						<>
							<EmptyScreen
								loading={loading}
								button={true}
								btnIcon={false}
								title="No Fee Details recorded yet"
								subTitle="Fees created on the platform get to show up here. There are none for now."
								buttonLabel="Create Fee Detail"
								onClick={() => {
									setShowModal((prev) => {
										return { ...prev, create: true };
									});
								}}
							/>
						</>
					) : (
						<>
							{/* dispute content start */}
							<div
								style={{ marginBottom: '1.5rem' }}
								className="global-search-pagination-filter-box"
							>
								{' '}
								<SmartFilter
									hideFilter
									// hideExport
									searchTitle="Search by category"
									defaultFilterBy="status"
									// filters={[
									// 	{
									// 		label: 'Status',
									// 		filterBy: 'status',
									// 		options: [
									// 			{
									// 				label: 'Pending',
									// 				value: 'pending',
									// 			},
									// 			{
									// 				label: 'Failed',
									// 				value: 'failed',
									// 			},
									// 			{
									// 				label: 'Successful',
									// 				value: 'succesful',
									// 			},
									// 		],
									// 	},
									// ]}
									onExport={() => {
										setShowModal((prev) => {
											return { ...prev, export: true };
										});
									}}
									page="bankbox_transactions"
									onSearchChange={debounce((e) => {
										dispatch(
											bankboxAPI.getAllFees({
												per_page: 20,
												page: currentPage,
												search: e,
											})
										);
									}, 500)}
								/>
								<div className="pagination-wrap">
									{' '}
									<Pagination
										className="top-bar__pagination"
										currentPage={currentPage}
										itemsPerPage={all_fees?.pagination?.per_page}
										totalItems={all_fees?.pagination?.total}
										onPageChange={function (page: number): void {
											setCurrentPage(page);
										}}
									/>
								</div>
							</div>
							<div
								// onClick={() => {
								// 	console.log(all_fees);
								// }}
								className="fee-detail-dashboard-content-index-wrap"
							>
								{/* table content start */}
								<div className="transaction__table non-mobile">
									<RavenTable
										action={false}
										className="table__main"
										headerList={[
											'FEE CATEORY',
											'FEE',
											'CAP',
											'TRF FEE',
											'COLLECTION FEE',
											'MANDATORY FEE',
											'DATE ADDED',
											'ACTIONS',
										]}
									>
										{all_fees?.data?.map((chi: any, idx: any) => {
											const {
												transfer_fee,
												created_at,
												reference,
												cap,
												bank_collection_fee,
												mandatory_fee,
											} = chi || {};
											return (
												<RavenTableRow
													loading={loading}
													one={
														capitalizeFirstWord(
															formatTypeFunction(reference) as any
														) || '----'
													}
													two={`${chi?.fee || 0}%`}
													three={symbol('ngn') + formatNumWithComma(cap, 'ngn')}
													key={idx}
													five={
														symbol('ngn') +
														formatNumWithComma(transfer_fee, 'ngn')
													}
													four={
														symbol('ngn') +
														formatNumWithComma(bank_collection_fee, 'ngn')
													}
													six={
														symbol('ngn') +
														formatNumWithComma(mandatory_fee, 'ngn')
													}
													// seven={formatDateTime(created_at)}
													seven={<DateFragmentBox date={chi?.created_at} />}
													// manualActionIcon={() => {

													// }}
													eight={
														<div
															style={{ position: 'relative' }}
															onClick={(e) => {
																e.stopPropagation();
																setShowDrop((prev) => {
																	return {
																		...prev,
																		id: idx,
																		show:
																			showDrop?.id === idx
																				? !showDrop.show
																				: true,
																	};
																});
															}}
														>
															<DropMore
																idx={idx}
																className={isDropped(idx) && 'show-drop'}
																chi={chi}
															/>
															{icons.three_dots}
														</div>
													}
												/>
											);
										})}
									</RavenTable>
								</div>
								{/* table content end */}
							</div>
							{/* dispute content end */}
						</>
					)}
				</PageLayout>
			</DashboardLayout>
			{/* modal start */}
			<CreateFeeCategoryModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, create: false };
					});
				}}
				visible={showModal?.create}
				onFinish={handleFinish}
			/>
			{/* modal end */}
			<ExportModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, export: false };
					});
				}}
				visible={showModal?.export}
				page="fees"
				onFinishExport={(param) => {
					// console.log(param);
					window.open(param, '_blank')?.focus();
				}}
			/>
			<ViewFeeCategoryModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, view_fee: false };
					});
				}}
				visible={showModal?.view_fee}
				// onFinish={handleFinish}
				detail={singleChild}
			/>
			<EditFeeCategoryModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, edit_fee: false };
					});
				}}
				visible={showModal?.edit_fee}
				onFinish={handleFinish}
				detail={singleChild}
			/>
		</>
	);
};

export default FeeDetailsIndexPage;
