

export const generalSettingsList  = [
  'Basic Details',
  'Settlement Account',
  'Price, Rates & Fees',
  'Operations & Support',
  'Webhook & Keys',
  'EOD',
  'Pickup Stations',
];