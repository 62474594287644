import React, { useState } from 'react';
import DeleteModal from '../../../../../components/common/deleteModal/DeleteModal';
import {
	capitalizeFirstLetter,
	trimLongString,
} from '../../../../../utils/helper/Helper';
import { useDispatch } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';

interface MyComponentProps {
	visible?: boolean;
	onClose?: () => void;
	details?: any;
	onFinish?: () => void;
}

const RemoveMemberModal = ({
	details,
	onClose,
	visible,
	onFinish,
}: MyComponentProps) => {
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
  const handleSubmit = async () => {
		const obj = {
			team_id: details?.id,
		};
		setLoading(true);
		const data = await dispatch(bankboxAPI.removeTeamMember(obj as any));

		if (data?.payload?.status === 'success') {
			setLoading(false);
			onFinish && onFinish();
		} else {
			setLoading(false);
		}
	};

	return (
		<>
			<DeleteModal
				onDelete={handleSubmit}
				loading={loading}
				deleteText={`Remove Team Member`}
				buttonColor={''}
				visible={visible as boolean}
				onCancel={onClose}
				title={`Remove Team memeber ( ${
					details?.first_name?.length + details?.last_name?.length < 12
						? `${capitalizeFirstLetter(details?.first_name) || ''} ${
								details?.last_name
						  }`
						: `${details?.first_name} ${trimLongString(details?.last_name, 4)}`
				} )`}
				text={`Are you sure you want to remove this member from the list of all the members that you have?.`}
			/>
		</>
	);
};

export default RemoveMemberModal;
