import React from 'react';
import './styles/ReuseableDescription.css';
import { RavenButton } from '@ravenpay/raven-bank-ui';
import { useNavigate } from 'react-router-dom';

interface MyComponentProps {
	type?: string;
	text?: string;
	title?: string;
}

const ReuseableDescription = ({ type, text, title }: MyComponentProps) => {
	const navigate = useNavigate();
	return (
		<div className="reusable-description-box-wrapper-index">
			{/* top box start */}
			<div
				className={`top-box ${
					type === 'wrong'
						? `top-box-wrong`
						: type === 'success'
						? `top-box-success`
						: ''
				}`}
			></div>
			{/* top box end */}
			{/* bottom box start */}
			<div className="bottom-box">
				<p className="title">{title}</p>
				<p className="text">{text}</p>
			</div>
			{/* bottom box end */}

			{type === 'success' && (
				<div
					style={{
						padding: '0rem 2rem',
						marginTop: '4rem',
						marginBottom: '2rem',
					}}
					className=""
				>
					{' '}
					<RavenButton
						onClick={() => {
							navigate('/login');
						}}
						color="deep-green-light"
						label="Continue to Sign In"
						style={{ width: '100%' }}
					/>
				</div>
			)}
		</div>
	);
};

export default ReuseableDescription;
