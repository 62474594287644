import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Function to check if the image URL is valid
const checkImageURL = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

// Component props interface
interface MyComponentProps {
  url?: string;
  children?: React.ReactNode;
  className?: string;
  styles?: any;
  loadSize?: any;
  circleSkeleton?: any;
  onClick?: () => void;
}

// ImageFragment component
const ImageFragment = ({
  url,
  children,
  className,
  styles,
  loadSize,
  circleSkeleton,
  onClick,
}: MyComponentProps) => {
  const [imageValid, setImageValid] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const imageUrl = url;

    if (imageUrl) {
      checkImageURL(imageUrl).then((isValid) => {
        setImageValid(isValid as boolean);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [url]);

  if (loading) {
    return (
      <div style={{ overflow: "hidden" }} className="img-box">
        <Skeleton
          width={loadSize || 35}
          height={loadSize || 35}
          circle={circleSkeleton || true}
        />
      </div>
    ); // You can customize the loading indicator as needed
  }

  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
    >
      {imageValid ? (
        <figure style={styles} className={`img-box ${className && className}`}>
          <img src={url} alt="" className="img" />
        </figure>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default ImageFragment;
