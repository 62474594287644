import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './styles/index.css';
import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	RavenModal,
	RavenToggleSlide,
} from '@ravenpay/raven-bank-ui';
// import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { businessCategories } from '../../data/businessCategory';
import { nigerianStates } from '../../data/statesInNigeria';
import { ThreeDots } from 'react-loader-spinner';
// import { RavenInputProps } from '@ravenpay/raven-bank-ui/dist/esm/components/types';
import {
	capitalizeAllLetter,
	filterEmptyActions,
	filterEmptyColumns,
	formatNumWithComma,
	getPermissionNames,
	getPolicyNames,
	reactSelectStyle,
	removeDuplicatesById,
	removeSpace,
	replaceUnderscoreWithSpace,
	symbol,
} from '../../../../../utils/helper/Helper';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useDarkMode } from '../../../../../hooks/useDarkMode';

const UpdateRoleAndPermision = ({
	onComplete,
	visible,
	onClose,
}: {
	onComplete: () => void;
	onClose?: () => void;
	visible?: boolean;
}) => {
	const { loading_roles, policies, single_role } = useSelector(
		(state: RootState) => state.bankbox
	);
	const [policySelect, setPolicySelect] = useState<string[]>(['overview']);
	// const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'role',
		completed: [],
		label: 'Update Role',
	});

	// const fileInputRef = useRef<HTMLInputElement>(null);
	// const imgPreviewRef = useRef<HTMLImageElement>(null);
	const { all_fees_no_page, partner_info, loading_fee, banks } = useSelector(
		(state: RootState) => state.bankbox
	);
	const { profile } = useSelector((state: RootState) => state.settings);
	const [formData, setFormData] = React.useState({
		description: '',
		roleName: '',
	});

	const { loading } = useSelector((state: RootState) => state.bankbox);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};
	const dispatch = useDispatch();

	// const brandColors = ['0B8376', '0B8376', 'EA872D', 'B9DEFE'];

	React.useEffect(() => {
		if (banks?.length < 1) {
			dispatch(bankboxAPI.getBankboxBanks({}));
		}
	}, []);

	const handleSelectPolicyName = (param: string) => {
		// console.log(param);

		if (param) {
			if (policySelect?.includes(param)) {
				const newList = policySelect?.filter((chi) => chi !== param);
				handleRemovePolicyPermission(param);
				setPolicySelect(newList);
			} else {
				const newList = [...policySelect, param];
				setPolicySelect(newList);
			}
		}
	};

	const [permissionToSubmit, setPermissionToSubmit] = useState<any>([]);

	const getObjPermission = (name: string) => {
		if (name) {
			const obj = permissionToSubmit?.find(
				(chi: any) => chi?.PolicyName === name
			);

			return obj === undefined
				? {
						PolicyName: name,
						actions: [],
				  }
				: obj;
		}
	};

	const handlePolicyPermission = (name: string, value: string) => {
		if (policySelect?.includes(name)) {
			// get obj
			const obj = getObjPermission(name);
			const checkExistObj = permissionToSubmit?.find(
				(chi: any) => chi?.PolicyName === name
			);
			// console.log(checkExistObj);

			// check if exist
			if (obj?.actions?.includes(value)) {
				const newList = obj?.actions?.filter((chi: any) => chi !== value);

				const newPermissionList = permissionToSubmit?.map((chi: any) =>
					chi?.PolicyName === name ? { ...chi, actions: newList } : chi
				);

				setPermissionToSubmit(newPermissionList);
			} else if (
				obj?.PolicyName === name &&
				obj?.actions?.length < 1 &&
				checkExistObj === undefined
			) {
				// console.log(value);

				const newList = [...permissionToSubmit, { ...obj, actions: [value] }];
				// console.log(newList);

				setPermissionToSubmit(newList);
			} else {
				const newList = [...obj?.actions, value];
				const newPermissionList = permissionToSubmit?.map((chi: any) =>
					chi?.PolicyName === name ? { ...chi, actions: newList } : chi
				);
				setPermissionToSubmit(newPermissionList);
			}
		}
	};

	const handleRemovePolicyPermission = (name: string) => {
		if (name) {
			const newPermissionList = permissionToSubmit?.map((chi: any) =>
				chi?.PolicyName === name ? { ...chi, actions: [] } : chi
			);
			setPermissionToSubmit(newPermissionList);
		}
	};

	useEffect(() => {
		if (permissionToSubmit?.length < 1 && visible) {
			// console.log(policies);
			const newList = policies?.map((chi: any) => {
				return {
					policyName: chi?.policyName,
					actions: [],
				};
			});
			setPermissionToSubmit(newList);
		}
	}, [policies, visible]);

	const [loadingCreate, setLoadingCreate] = useState(false);
	const handleSubmitPermission = async () => {
		const arrayNew = filterEmptyActions(permissionToSubmit);
    const perferctArray = (param?: any) => {
      if(param?.length > 0){
        const newList = param?.map((chi: any) => {
          return {actions: chi?.actions, policyName: chi?.PolicyName}
        })
        return newList;
      }
    }
		const obj = {
			...formData,
			policies: perferctArray(filterPoliciesByNames(arrayNew as any, policySelect)),
			roleId: String(single_role[0]?.roleId),
		};
		// console.log(obj);

		setLoadingCreate(true);
		const resp = await dispatch(bankboxAPI.updateRole(obj));

		if (resp.payload?.status === 'success') {
			onComplete && onComplete();
			handleClose();
			setLoadingCreate(false);
		} else {
			setLoadingCreate(false);
		}
	};

	const handleClose = () => {
		setPermissionToSubmit([]);
		setStep({
			...step,
			id: 'role',
		});
		setFormData({ description: '', roleName: '' });
		setPolicySelect(['overview']);
		onClose && onClose();
	};

	type Policy = {
		PolicyName: string;
		actions: string[];
	};

	function filterPoliciesByNames(
		policies: Policy[],
		allowedNames: string[]
	): Policy[] {
		return policies.filter((policy) =>
			allowedNames.includes(policy.PolicyName)
		);
	}

	useEffect(() => {
		if (Object?.keys(single_role)?.length > 0 && visible) {
			const roleInfo = single_role[0];
			if (Object?.keys(roleInfo)?.length > 0) {
				setPolicySelect(getPolicyNames(roleInfo?.policies));
				setPermissionToSubmit(preparePermissionFunc(roleInfo?.policies));
				setFormData((prev) => {
					return {
						...prev,
						roleName: roleInfo?.roleName,
						description: roleInfo?.roleDescription,
					};
				});
			}
		}
	}, [single_role, visible]);

	const preparePermissionFunc = (list: any) => {
		if (list?.length > 0) {
			const newList = list?.map((chi: any) => {
				return {
					PolicyName: chi?.policyName,
					actions: getPermissionNames(chi?.actions),
				};
			});
			return newList;
		}
	};

	return (
		<RavenModal
			visble={visible}
			onClose={handleClose}
			className={'bankbox-create-modal bankbox-create-setup-modal'}
			onBtnClick={function (e?: any): void {
				throw new Error('Function not implemented.');
			}}
		>
			<div
				// onClick={() => {
				// 	console.log(partner_info);
				// }}
				className="bankbox-modals"
				// style={{ minHeight: '70rem' }}
			>
				<div
					className={`bankbox-modals__left ${
						step.id === 'permission' ? `bankbox-modals__left-two` : ``
					}  `}
				>
					<div className="bankbox-modals__left--bg-absolute"></div>
					<div className="bankbox-modals__left--stepper">
						<div
							onClick={() =>
								setStep({
									...step,
									id: 'role',
								})
							}
							className={`stepper-item ${'active'} ${
								step.completed.includes('role') && 'completed'
							}`}
						>
							<span
								onClick={() =>
									setStep({
										...step,
										id: 'role',
									})
								}
							>
								<p>1</p>
							</span>
							<h6>Role Details</h6>
						</div>

						<div
							onClick={() => {
								step.completed.includes('permission') &&
									'completed' &&
									setStep({
										...step,
										id: 'permission',
									});
							}}
							className={`stepper-item ${
								(step.id === 'permission' || step.id === 'address') && 'active'
							} ${step.completed.includes('permission') && 'completed'}`}
						>
							<span>
								<p>2</p>
							</span>
							<h6>Permission and Control</h6>
						</div>
					</div>
				</div>

				<div className="bankbox-modals__right">
					{step.id === 'role' && (
						<div className="bankbox-modals__right--content">
							<div className="basic-content__title">
								<h5>Role Details</h5>
								<h6>{step.label}</h6>
							</div>

							<div className="price-content__input-contain">
								<RavenInputField
									name="roleName"
									onChange={handleChange}
									placeholder="E.g ‘Operations’"
									label="Role Title"
									value={formData.roleName}
									type="text"
									color="deep-green-light"
								/>

								<RavenInputField
									name="description"
									onChange={handleChange}
									label="Role Description"
									value={formData.description}
									placeholder="Enter role Description"
									type="textarea"
									color="deep-green-light"
									capitalize
								/>
							</div>
							<div className="price-content__form-submit">
								<RavenButton
									onClick={() => {
										setStep({
											id: 'permission',
											label: 'Confiugure This Role',
											completed: ['role, permission'],
										});
										// console.log(single_role);
									}}
									color="deep-green-light"
									label="Continue"
								/>{' '}
							</div>
						</div>
					)}

					{step.id === 'permission' && (
						<div className="bankbox-modals__right--content">
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'row',
									// backgroundColor: 'red',
									alignItems: 'center',
								}}
								className="basic-content__title"
							>
								<div className="">
									<h5>Permission and Control</h5>
									<h6>{step.label}</h6>
								</div>
								<RavenButton
									color="deep-green-light"
									onClick={handleSubmitPermission}
									label="Update Role"
									loading={loadingCreate}
								/>
							</div>

							<div className="left-right-box-wrap border-theme">
								{/* left box strart */}
								<div className="left-box border-theme-right">
									<div className="module-list-box grey-bg">
										{policies?.map((chi: any, idx: any) => {
											return (
												<div key={idx} className="item-row">
													<p className="name"> {chi?.policyName}</p>
													<div className="toggle-name-box">
														<RavenToggleSlide
															checked={policySelect?.includes(chi?.policyName)}
															color={`deep-green-light`}
															key={`${idx}-${chi?.policyName}`}
															onChange={() => {
																handleSelectPolicyName(chi?.policyName);
															}}
															id={`${idx}-${chi?.policyName}-psos`}
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								{/* left box end */}
								{/* right box strart */}
								<div className="right-box">
									{/* box-title- permissions start */}
									{policies?.map((chi: any, idx: any) => {
										if (!policySelect?.includes(chi?.policyName)) {
											return <React.Fragment key={idx}></React.Fragment>;
										}
										return (
											<div key={idx} className="title-permissions-box">
												<p className="module-title">{chi?.policyName}</p>
												<div className="permission-box">
													{removeDuplicatesById(chi?.actions)?.map(
														(child: any, i: any) => {
															return (
																<div
																	key={i}
																	className="permission-row border-theme"
																>
																	<label
																		className="permission-row-label"
																		htmlFor=""
																		// onClick={() => {console.log('kjhs');
																		// }}
																	>
																		<RavenCheckBox
																			onChange={() => {
																				// console.log('jh');

																				handlePolicyPermission(
																					chi?.policyName,
																					child?.actionName
																				);
																			}}
																			checked={getObjPermission(
																				chi?.policyName
																			)?.actions?.includes(child?.actionName)}
																			id={
																				`${i}-${chi?.policyName}-${idx}` as any
																			}
																			color={`deep-green-light`}
																			key={`${i}-${chi?.policyName}` as any}
																		/>
																		<p className="des">
																			{replaceUnderscoreWithSpace(
																				child?.actionName
																			)}
																		</p>
																	</label>
																</div>
															);
														}
													)}
												</div>
											</div>
										);
									})}

									{/* box-title- permissions end */}
								</div>
								{/* right box end */}
							</div>
						</div>
					)}
				</div>
			</div>
		</RavenModal>
	);
};

export default UpdateRoleAndPermision;
