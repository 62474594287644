import axios from 'axios';
import env from '../env';
import { decrypt, decrypt2, encrypt2 } from './encrypt';
import { logger } from '../services/logger';
import { getCookie, setCookie } from './helper/Helper';
import { toast } from '@ravenpay/raven-bank-ui';

const API = env.bankbox_api;

/** base url to make request to the BE end point */

const instance = axios.create({
	baseURL: API,
});

let bankbox_token = '';
instance.interceptors.request.use(
	async (config) => {
		// const tokenTwo
		bankbox_token = getCookie('x-pos-key') as string;
		const xPosLocal = localStorage?.getItem('x-p');
		// console.log(xPosLocal);
		// console.log(bankbox_token);
		
		if (bankbox_token || xPosLocal) {
			config.headers['x-pos-key'] = bankbox_token || xPosLocal;
			config.headers['x-channel'] = 'web';
		}

		try {
			// console.log(config.data);
			const encryptedData = await encrypt2(config.data ?? '');
			// console.log(encryptedData);

			config.data = { data: encryptedData };
		} catch (error) {
			logger.log(error);
		}
		// console.log(config);

		return config;
	},
	(error) => {
		// console.log(
		// 	"hukhjk"
		// );

		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	async (response) => {
		// console.log(response);

		response.data.data = decrypt2(response.data.data);
		delete response.config.transformRequest;
		delete response.data?.header;
		// console.log(response, "--------bankbox axios -----");
		setCookie('denounce-app-key', '', 300);
		setCookie('denounce-bankbox', '', 300);

		return response;
	},
	async (error) => {
		// console.log(error);

		let val;
		if (
			error.response.data.status === 'fail' &&
			decrypt2(error.response.data.data) ===
				'marchant bankbox profile not found'
		) {
			setCookie('x-pos-key', '', 0);
			setCookie('denounce-app-key', bankbox_token, 300);
			return;
		} else {
			setCookie('denounce-app-key', '0', 300);
		}

		if (
			error.response.data.status === 'fail' &&
			decrypt2(error.response.data.data) === 'pos key missing'
		) {
			return;
		}

		// logger.log(decrypt2(error.response.data.data), 'the error');marchant bankbox profile not found
		if (error.response.data.status === 'fail') {
			// toast.error(error.response.data.message, {
			// 	position: 'top-right',
			// });
			val = error.response;
			delete val.config.transformRequest;
		}

		val.data.data = decrypt2(val.data.data);

		// console.log(val);

		return val;
	}
);

export default instance;
