import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import './styles/index.css';
import {
	RavenButton,
	RavenCheckBox,
	RavenInputField,
	RavenModal,
	RavenToggleSlide,
} from '@ravenpay/raven-bank-ui';
// import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState from '../../../../../redux/types';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { businessCategories } from '../../data/businessCategory';
import { nigerianStates } from '../../data/statesInNigeria';
import { ThreeDots } from 'react-loader-spinner';
// import { RavenInputProps } from '@ravenpay/raven-bank-ui/dist/esm/components/types';
import {
	capitalizeAllLetter,
	filterEmptyActions,
	filterEmptyColumns,
	formatNumWithComma,
	reactSelectStyle,
	removeDuplicatesById,
	removeSpace,
	replaceUnderscoreWithSpace,
	symbol,
} from '../../../../../utils/helper/Helper';
import BadgeComponent from '../../../../../components/common/badge/BadgeComponent';
import { useDarkMode } from '../../../../../hooks/useDarkMode';

const CreateRoleAndPermision = ({
	onComplete,
	visible,
	onClose,
}: {
	onComplete: () => void;
	onClose?: () => void;
	visible?: boolean;
}) => {
	const { loading_roles, policies } = useSelector(
		(state: RootState) => state.bankbox
	);
	const [policySelect, setPolicySelect] = useState<string[]>(['overview']);
	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [step, setStep] = React.useState<{
		id: string;
		completed: string[];
		label: string;
	}>({
		id: 'role',
		completed: [],
		label: 'Create A Role',
	});

	// const fileInputRef = useRef<HTMLInputElement>(null);
	// const imgPreviewRef = useRef<HTMLImageElement>(null);
	const { all_fees_no_page, partner_info, loading_fee, banks } = useSelector(
		(state: RootState) => state.bankbox
	);
	const { profile } = useSelector((state: RootState) => state.settings);
	const [formData, setFormData] = React.useState({
		description: '',
		roleName: '',
	});

	const { loading } = useSelector((state: RootState) => state.bankbox);
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
	};
	const dispatch = useDispatch();

	const verifyIcon = (
		<svg
			className="img"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.01322 2.76406C5.52767 2.72301 6.01607 2.52071 6.40887 2.18597C7.32551 1.40481 8.67371 1.40481 9.59035 2.18597C9.98315 2.52071 10.4715 2.72301 10.986 2.76406C12.1865 2.85987 13.1398 3.81318 13.2356 5.01371C13.2767 5.52816 13.479 6.01655 13.8137 6.40936C14.5949 7.326 14.5949 8.67419 13.8137 9.59084C13.479 9.98364 13.2767 10.472 13.2356 10.9865C13.1398 12.187 12.1865 13.1403 10.986 13.2361C10.4715 13.2772 9.98315 13.4795 9.59035 13.8142C8.67371 14.5954 7.32551 14.5954 6.40887 13.8142C6.01607 13.4795 5.52767 13.2772 5.01322 13.2361C3.81269 13.1403 2.85938 12.187 2.76357 10.9865C2.72252 10.472 2.52022 9.98364 2.18548 9.59084C1.40432 8.67419 1.40432 7.326 2.18548 6.40936C2.52022 6.01655 2.72252 5.52816 2.76357 5.01371C2.85938 3.81318 3.81269 2.85987 5.01322 2.76406ZM10.9653 6.96578C11.2777 6.65336 11.2777 6.14683 10.9653 5.83441C10.6529 5.52199 10.1463 5.52199 9.83392 5.83441L7.19961 8.46873L6.16529 7.43441C5.85288 7.12199 5.34634 7.12199 5.03392 7.43441C4.7215 7.74683 4.7215 8.25336 5.03392 8.56578L6.63392 10.1658C6.94634 10.4782 7.45288 10.4782 7.76529 10.1658L10.9653 6.96578Z"
				fill="#EA872D"
			/>
		</svg>
	);

	// const brandColors = ['0B8376', '0B8376', 'EA872D', 'B9DEFE'];

	React.useEffect(() => {
		if (banks?.length < 1) {
			dispatch(bankboxAPI.getBankboxBanks({}));
		}
	}, []);

	const handleSelectPolicyName = (param: string) => {
		if (param) {
			if (policySelect?.includes(param)) {
				const newList = policySelect?.filter((chi) => chi !== param);
				handleRemovePolicyPermission(param);
				setPolicySelect(newList);
			} else {
				const newList = [...policySelect, param];
				setPolicySelect(newList);
			}
		}
	};

	const [permissionToSubmit, setPermissionToSubmit] = useState<any>([]);

	const getObjPermission = (name: string) => {
		if (name) {
			const obj = permissionToSubmit?.find(
				(chi: any) => chi?.policyName === name
			);
			return obj;
		}
	};

	const handlePolicyPermission = (name: string, value: string) => {
		if (policySelect?.includes(name)) {
			// get obj
			const obj = getObjPermission(name);
			// check if exist
			if (obj?.actions?.includes(value)) {
				const newList = obj?.actions?.filter((chi: any) => chi !== value);

				const newPermissionList = permissionToSubmit?.map((chi: any) =>
					chi?.policyName === name ? { ...chi, actions: newList } : chi
				);
				setPermissionToSubmit(newPermissionList);
			} else {
				const newList = [...obj?.actions, value];
				const newPermissionList = permissionToSubmit?.map((chi: any) =>
					chi?.policyName === name ? { ...chi, actions: newList } : chi
				);
				setPermissionToSubmit(newPermissionList);
			}
		}
	};

	const handleRemovePolicyPermission = (name: string) => {
		if (name) {
			const newPermissionList = permissionToSubmit?.map((chi: any) =>
				chi?.policyName === name ? { ...chi, actions: [] } : chi
			);
			setPermissionToSubmit(newPermissionList);
		}
	};

	useEffect(() => {
		if (permissionToSubmit?.length < 1 && visible) {
			// console.log(policies);
			const newList = policies?.map((chi: any) => {
				return {
					policyName: chi?.policyName,
					actions: [],
				};
			});
			setPermissionToSubmit(newList);
		}
	}, [policies, visible]);

	const [loadingCreate, setLoadingCreate] = useState(false);
	const handleSubmitPermission = async () => {
		const arrayNew = filterEmptyActions(permissionToSubmit);
		const obj = {
			...formData,
			policies: arrayNew,
		};
		// console.log(obj);

		setLoadingCreate(true)
		const resp = await dispatch(bankboxAPI.createRole(obj));

		if (resp.payload?.status === 'success'){
      onComplete && onComplete();
		  handleClose()
		  setLoadingCreate(false)
		}
		else{
		  setLoadingCreate(false)
		}
	};

	const handleClose = () => {
		setPermissionToSubmit([]);
		setStep({
			...step,
			id: 'role',
		});
		setFormData({ description: '', roleName: '' });
		setPolicySelect(['overview']);
		onClose && onClose();
	};

	return (
		<RavenModal
			visble={visible}
			onClose={handleClose}
			className={'bankbox-create-modal bankbox-create-setup-modal'}
			onBtnClick={function (e?: any): void {
				throw new Error('Function not implemented.');
			}}
		>
			<div
				// onClick={() => {
				// 	console.log(partner_info);
				// }}
				className="bankbox-modals"
				// style={{ minHeight: '70rem' }}
			>
				<div
					className={`bankbox-modals__left ${
						step.id === 'permission' ? `bankbox-modals__left-two` : ``
					}  `}
				>
					<div className="bankbox-modals__left--bg-absolute"></div>
					<div className="bankbox-modals__left--stepper">
						<div
							onClick={() =>
								setStep({
									...step,
									id: 'role',
								})
							}
							className={`stepper-item ${'active'} ${
								step.completed.includes('role') && 'completed'
							}`}
						>
							<span
								onClick={() =>
									setStep({
										...step,
										id: 'role',
									})
								}
							>
								<p>1</p>
							</span>
							<h6>Role Details</h6>
						</div>

						<div
							onClick={() => {
								step.completed.includes('permission') &&
									'completed' &&
									setStep({
										...step,
										id: 'permission',
									});
							}}
							className={`stepper-item ${
								(step.id === 'permission' || step.id === 'address') && 'active'
							} ${step.completed.includes('permission') && 'completed'}`}
						>
							<span>
								<p>2</p>
							</span>
							<h6>Permission and Control</h6>
						</div>
					</div>
				</div>

				<div className="bankbox-modals__right">
					{step.id === 'role' && (
						<div className="bankbox-modals__right--content">
							<div className="basic-content__title">
								<h5>Role Details</h5>
								<h6>{step.label}</h6>
							</div>

							<div className="price-content__input-contain">
								<RavenInputField
									name="roleName"
									onChange={handleChange}
									placeholder="E.g ‘Operations’"
									label="Role Title"
									value={formData.roleName}
									type="text"
									color="deep-green-light"
								/>

								<RavenInputField
									name="description"
									onChange={handleChange}
									label="Role Description"
									value={formData.description}
									placeholder="Enter role Description"
									type="textarea"
									color="deep-green-light"
                  capitalize
								/>
							</div>
							<div className="price-content__form-submit">
								<RavenButton
									onClick={() =>
										setStep({
											id: 'permission',
											label: 'Confiugure This Role',
											completed: ['role, permission'],
										})
									}
									disabled={!formData?.description || !formData?.roleName}
									color="deep-green-light"
									label="Continue"
								/>{' '}
							</div>
						</div>
					)}

					{step.id === 'permission' && (
						<div className="bankbox-modals__right--content">
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									flexDirection: 'row',
									// backgroundColor: 'red',
									alignItems: 'center',
								}}
								className="basic-content__title"
							>
								<div className="">
									<h5>Permission and Control</h5>
									<h6>{step.label}</h6>
								</div>
								<RavenButton
									color="deep-green-light"
									onClick={handleSubmitPermission}
									label="Create Role"
									loading={loadingCreate}
									disabled={
										filterEmptyActions(permissionToSubmit)[0]?.actions?.length <
											1 || filterEmptyActions(permissionToSubmit)?.length < 1
									}
								/>
							</div>

							<div
								
								className="left-right-box-wrap border-theme"
							>
								{/* left box strart */}
								<div className="left-box border-theme-right">
									<div className="module-list-box grey-bg">
										{policies?.map((chi: any, idx: any) => {
											return (
												<div key={idx} className="item-row">
													<p className="name"> {chi?.policyName}</p>
													<div className="toggle-name-box">
														<RavenToggleSlide
															checked={policySelect?.includes(chi?.policyName)}
															color={`deep-green-light`}
															key={`${idx}-${chi?.policyName}`}
															onChange={() => {
																handleSelectPolicyName(chi?.policyName);
															}}
															id={`${idx}-${chi?.policyName}`}
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								{/* left box end */}
								{/* right box strart */}
								<div className="right-box">
									{/* box-title- permissions start */}
									{policies?.map((chi: any, idx: any) => {
										if (!policySelect?.includes(chi?.policyName)) {
											return <React.Fragment key={idx}></React.Fragment>;
										}
										return (
											<div key={idx} className="title-permissions-box">
												<p className="module-title">{chi?.policyName}</p>
												<div className="permission-box">
													{removeDuplicatesById(chi?.actions)?.map(
														(child: any, i: any) => {
															return (
																<div
																	key={i}
																	className="permission-row border-theme"
																>
																	<label
																		className="permission-row-label"
																		htmlFor=""
																	>
																		<RavenCheckBox
																			onChange={() => {
																				handlePolicyPermission(
																					chi?.policyName,
																					child?.actionName
																				);
																			}}
																			checked={getObjPermission(
																				chi?.policyName
																			)?.actions?.includes(child?.actionName)}
																			id={`${i}-${chi?.policyName}` as any}
																			color={`deep-green-light`}
																		/>
																		<p className="des">
																			{replaceUnderscoreWithSpace(
																				child?.actionName
																			)}
																		</p>
																	</label>
																</div>
															);
														}
													)}
												</div>
											</div>
										);
									})}

									{/* box-title- permissions end */}
								</div>
								{/* right box end */}
							</div>
						</div>
					)}
				</div>
			</div>
		</RavenModal>
	);
};

export default CreateRoleAndPermision;
